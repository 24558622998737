import '../settings.scss';
//  <--HOOKS--> //
import { useToast } from '../../../config/hooks/useToast';
import { useAlert } from '../../../config/hooks/useAlert';
import { useCallApi } from '../../../config/hooks/useCallApi';
//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import SupplyTableComponent from './components/supply-table';
import ButtonBody from '../../../components/button/button';
import { lang } from '../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
const Supply = () => {
    const { ToastElement, toastManagerRef } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const key = defaultLang;
    const { LoadingData, LoaderElement } = useCallApi();
    const {
        [key as keyof typeof lang]: {
            pageSupply: { title, adminTitle, newTitle }
        }
    } = lang;

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/supplyIcon.svg'
                kind='settings'
                option='Supply'
            />
            <div className='settings'>
                <Header title={{ name: title }} icon='SupplyIconB.svg' />
                <div
                    className='settings__grid-container'
                    id='SupplyPage/SupplyList'
                >
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title'>
                            <div className='settings__grid-container__item__title__section1 title'>
                                {adminTitle}
                            </div>
                        </div>
                        <SupplyTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/supply/create'
                            />
                        </div>
                    </div>
                </div>
                {LoadingData && <LoaderElement />}
            </div>
        </>
    );
};

export default Supply;
