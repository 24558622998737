import '../ranking.scss';
import { Doughnut } from 'react-chartjs-2';
import { NormalizeName } from '../../../../config/utils/Utilities';

interface Props {
    podio: any;
    data: any;
    pdf?: boolean;
}

const RankingList = ({ podio, data, pdf }: Props) => {
    return (
        <div
            id='RankPage/PropeRank/IUSelected'
            className='ranking__list'
            style={{ height: pdf ? 'auto' : '25%' }}
        >
            {!pdf && (
                <div className='ranking__list__title title'>
                    Ranking inmuebles/unidades seleccionadas
                </div>
            )}
            <div
                className='ranking__list__content'
                style={{ padding: pdf ? '0' : '3%' }}
            >
                {!pdf &&
                    (podio.length > 1 ? (
                        <div className='ranking__list__content__podium'>
                            {podio?.map((item, index) => (
                                <div
                                    className={`ranking__list__content__podium__item ${
                                        index === 1
                                            ? 'ranking__list__content__podium__item--first'
                                            : index === 2
                                            ? 'ranking__list__content__podium__item--third'
                                            : ''
                                    }`}
                                    key={index}
                                >
                                    <div className='ranking__list__content__podium__item__header'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src={`/svg-icons/${
                                                index === 0
                                                    ? 'SecondPlaceIcon.svg'
                                                    : index === 1
                                                    ? 'FirstPlaceIcon.svg'
                                                    : 'ThirdPlaceIcon.svg'
                                            }`}
                                        />
                                        <div className='ranking__list__content__podium__item__header__content'>
                                            <div
                                                className={`ranking__list__content__podium__item__header__content__name ${
                                                    pdf
                                                        ? 'ranking-pdf'
                                                        : 'subtitle'
                                                }`}
                                            >
                                                {item.propertyName ??
                                                    item.propertyUnitName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__chart'>
                                        <Doughnut
                                            className='ranking__list__content__podium__item__chart__doughtnut'
                                            data={{
                                                datasets: [
                                                    {
                                                        data: [
                                                            item?.BZeroIndexMonth,
                                                            100 -
                                                                item?.BZeroIndexMonth
                                                        ],
                                                        backgroundColor:
                                                            item.BZeroIndexMonth <=
                                                            50
                                                                ? [
                                                                      '#03dac5',
                                                                      '#fff'
                                                                  ]
                                                                : item.BZeroIndexMonth >
                                                                  75
                                                                ? [
                                                                      '#CE3E50',
                                                                      '#fff'
                                                                  ]
                                                                : [
                                                                      '#FDDF4A',
                                                                      '#fff'
                                                                  ],
                                                        borderColor: '#fff',
                                                        borderWidth: 8
                                                    }
                                                ]
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: true,
                                                        backgroundColor:
                                                            'rgb(255, 255, 255)',
                                                        titleColor:
                                                            'rgb(162 162 163)', // Color del texto del título del tooltip
                                                        bodyColor:
                                                            'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
                                                        footerColor:
                                                            'rgb(162 162 163)',
                                                        padding: 6,
                                                        boxPadding: 3,
                                                        usePointStyle: true
                                                    }
                                                },
                                                rotation: -90,
                                                circumference: 180,
                                                cutout: '75%',
                                                maintainAspectRatio: true,
                                                responsive: true
                                            }}
                                        />
                                        <div className='bzeroindex-card__content__chart__number bigtitle'>
                                            {item?.BZeroIndexMonth.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            )}
                                            %
                                        </div>
                                        <div
                                            className={`bzeroindex-card__content__chart__inf ${
                                                pdf
                                                    ? 'ranking-pdf'
                                                    : 'subsubtitle'
                                            }`}
                                        >
                                            0%
                                        </div>
                                        <div
                                            className={`bzeroindex-card__content__chart__sup ${
                                                pdf
                                                    ? 'ranking-pdf'
                                                    : 'subsubtitle'
                                            }`}
                                        >
                                            100%
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__detail'>
                                        <div
                                            className={`ranking__myproperty__content__data__item ${
                                                pdf ? 'ranking-pdf' : 'subtitle'
                                            }`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/Recyclable.svg'
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.RecyclableMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`ranking__myproperty__content__data__item ${
                                                pdf ? 'ranking-pdf' : 'subtitle'
                                            }`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src={`/svg-icons/material-icons/${NormalizeName(
                                                    'orgánicos'
                                                )}.svg`}
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.OrganicMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`ranking__myproperty__content__data__item ${
                                                pdf ? 'ranking-pdf' : 'subtitle'
                                            }`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src={`/svg-icons/material-icons/${NormalizeName(
                                                    'Basura'
                                                )}.svg`}
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item.TrashMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className={`ranking__list__content__message ${
                                pdf ? 'ranking-pdf' : 'subtitle'
                            }`}
                        >
                            No hay suficientes inmuebles/unidades que participen
                            del ranking.
                        </div>
                    ))}
                <div>
                    {data.length > 3 && (
                        <table
                            className={`ranking__list__content__table ${
                                pdf ? 'ranking-pdf' : 'subtitle'
                            }`}
                        >
                            <thead className='ranking__list__content__table__header'>
                                <th className='ranking__list__content__table__header__first-item'>
                                    #
                                </th>
                                <th className='ranking__list__content__table__header__item'>
                                    Inmueble/Unidad
                                </th>
                                <th className='ranking__list__content__table__header__item'>
                                    <img
                                        className='ranking-card__content-container__content__list-container__element__info__img'
                                        src='/svg-icons/DotIcon.svg'
                                    />
                                    Índice BZero [%]
                                </th>
                                <th className='ranking__list__content__table__header__item'>
                                    <img
                                        className='ranking__list__content__podium__item__header__logo'
                                        src='/svg-icons/Recyclable.svg'
                                    />
                                    Reciclables [kg]
                                </th>
                                <th className='ranking__list__content__table__header__item'>
                                    <img
                                        className='ranking__list__content__podium__item__header__logo'
                                        src={`/svg-icons/material-icons/${NormalizeName(
                                            'orgánicos'
                                        )}.svg`}
                                    />
                                    Orgánicos [kg]
                                </th>
                                <th className='ranking__list__content__table__header__item'>
                                    <img
                                        className='ranking__list__content__podium__item__header__logo'
                                        src={`/svg-icons/material-icons/${NormalizeName(
                                            'Basura'
                                        )}.svg`}
                                    />
                                    Basura [kg]
                                </th>
                            </thead>
                            <tbody className='ranking__list__content__table__body'>
                                {data
                                    .slice(3, data.length)
                                    .map((data, index) => (
                                        <tr
                                            key={
                                                data.property ??
                                                data.propertyUnit
                                            }
                                            className='ranking__list__content__table__body__row'
                                        >
                                            <td className='ranking__list__content__table__body__row__first-item'>
                                                {`#${parseInt(index) + 4}`}
                                            </td>
                                            <td className='ranking__list__content__table__body__row__item'>
                                                {data.propertyName ??
                                                    data.propertyUnitName}
                                            </td>
                                            <td className='ranking__list__content__table__body__row__item'>
                                                {data.BZeroIndexMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 1,
                                                        maximumFractionDigits: 1
                                                    }
                                                )}
                                                %
                                            </td>
                                            <td className='ranking__list__content__table__body__row__item'>
                                                {data.RecyclableMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </td>
                                            <td className='ranking__list__content__table__body__row__item'>
                                                {data.OrganicMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </td>
                                            <td className='ranking__list__content__table__body__row__item'>
                                                {data.TrashMonth.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RankingList;
