import { useEffect, useState } from 'react';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import TableComponent from '../../../../components/table';
import { ClienBusinessTable } from '../../../../components/table/data/columns_table';
//  <--SERVICES--> //
import { ApiClientBusinessByClient } from '../../../../config/service';
//  <--INTERFACE--> //
import { ClientRoleInterface } from '../../../../interface';
import { constantsTable } from './constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { setProductAndService } from '../../../../store/slices/permissions';
import { useParams } from 'react-router';
import './style.scss';

interface Props {
    refresh?: boolean;
}

const ClientBusinessTableComponent = ({ refresh }: Props) => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { id: clientId } = useParams();
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { keysFilter } = constantsTable;
    const [dataList, setDataList] = useState<ClientRoleInterface[]>([]);
    const dispatch = useAppDispatch();
    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        if (clientId) {
            const response = await GetData(
                ApiClientBusinessByClient(clientId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setDataList(data);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the COMPANY list
     */
    useEffect(() => {
        CallData();
    }, [refresh]);

    const AssignData = item => {
        dispatch(setProductAndService({ id: item.id, name: item.name }));
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={dataList}
                headers={ClienBusinessTable}
                showHeaders={true}
                showFilters={false}
                keysFilter={keysFilter}
                showPagination={true}
                callback={AssignData}
                addClass={'tblpropertiesunit'}
            />
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default ClientBusinessTableComponent;
