import '../../../../settings/settings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
//  <--COMPONENTS--> //
import FormBuilder from '../../../../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    ClientRoleFormCreateInterface,
    ClientRoleFormValues
} from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import { ApiClientRole, ApiClientRoleId } from '../../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../../langs';
import { FormCreate } from './form';
import '../../../../../scss/global/global.scss';
import ProductServicesPage from '..';
import { ProductAndServicesPagePath } from '../constants';

const CreateEditPage = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { queryId, id: roleId } = useParams();
    console.log(queryId, roleId);
    const {
        [defaultLang as keyof typeof lang]: {
            pageProductService: {
                buttonDelete,
                buttonNew,
                buttonDetail,
                buttonedit,
                deleteMessage
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<ClientRoleFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (roleId) {
            setEntityId(roleId);
        } else {
            setEntity(ClientRoleFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [roleId]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiClientRoleId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: ClientRoleFormCreateInterface) => {
        values.client = queryId;
        const response = roleId
            ? await GetData(
                  ApiClientRoleId(entityId),
                  HTTP_METHODS.PATCH,
                  values
              )
            : await GetData(ApiClientRole, HTTP_METHODS.POST, values);
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = {
            func: ConfirmDelete,
            param: roleId
        };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(
            ApiClientRoleId(id),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate(`/panel/${ProductAndServicesPagePath}`);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <ProductServicesPage updates={updates}>
            <>
                <AlertElement ref={alertManagerRef} />
                <ToastElement ref={toastManagerRef} />
                <div
                    className='settings__grid-container__item_right'
                    id='RoleByCli/RoleDetail'
                >
                    <div className='settings__grid-container__item_right__title title'>
                        {roleId ? (
                            <div>{detailPage ? buttonDetail : buttonedit}</div>
                        ) : (
                            <div>{buttonNew}</div>
                        )}
                    </div>
                    <div className='settings__grid-container__item_right__content'>
                        <>
                            {readyForm && (
                                <FormBuilder
                                    initialValues={
                                        entity?.name
                                            ? entity
                                            : ClientRoleFormValues
                                    }
                                    buttons={FormButtons}
                                    lang={defaultLang}
                                    formStructure={FormCreate}
                                    focusOn={fieldRef}
                                    config={{
                                        noClearButton: !!entity?.name,
                                        editButton: !!entity?.name && detailPage
                                    }}
                                />
                            )}
                        </>
                        {roleId && detailPage && (
                            <>
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <Link
                                        id='RoleByCli/RoleDetail/PermissionsConfigure'
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        to={`/panel/clients/${queryId}/roles/${roleId}/permissions`}
                                    >
                                        Configurar permisos
                                    </Link>
                                </div>
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        id='RoleByCli/RoleDetail/Delete'
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {buttonDelete}
                                    </button>
                                    <button
                                        id='RoleByCli/RoleDetail/Update'
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                        onClick={Edit}
                                    >
                                        {buttonedit}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    {LoadingData && <LoaderElement />}
                </div>
            </>
        </ProductServicesPage>
    );
};

export default CreateEditPage;
