export const constantsTable = {
    keysFilter: ['name', 'tag']
};

export const Level1ServicesPagePath = 'permissions-settings';
export const PageNameText = 'Configuración de permisos';

export interface ValuesInterface {
    id: string;
    name: string;
}

export const ICON_ASIDE_VALUES: ValuesInterface[] = [
    { id: '1', name: 'SÍ' },
    { id: '0', name: 'NO' }
];
