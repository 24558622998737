import '../../settings.scss';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import SupplyRequirementTableComponent from './supply-requirement-table';
import ButtonBody from '../../../../components/button/button';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--SERVICES--> //
import { ApiSupplyId } from '../../../../config/service';
//  <--INTERFACE--> //
import {
    SupplyFormValues,
    SupplyFormCreateInterface
} from '../../../../interface';
import { lang } from '../../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

const SupplyRequirement = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { GetData } = useCallApi();
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageSupply: {
                title,
                supplyRequirement: { newTitle }
            }
        }
    } = lang;
    /* const [showFont, setShowFont] = useState(true); */
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<SupplyFormCreateInterface>();

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiSupplyId(entityId), HTTP_METHODS.GET);
        const { data } = response;
        setEntity(data);
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(SupplyFormValues);
        }
    }, [id]);

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/supplyIconB.svg'
                kind='settings'
                option='Supply'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/supply' }}
                    icon='DataIconB.svg'
                    subtitle={{
                        name: id ? entity?.name ?? '' : '',
                        link: `/panel/supply/edit/${id}`
                    }}
                    subsubtitle={{ name: 'Requerimientos' }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <SupplyRequirementTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/supply/${id}/supply-requirement/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupplyRequirement;
