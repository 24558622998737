import { useEffect, useState } from 'react';
import 'react-dropdown-tree-select/dist/styles.css';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import {
    ApiProductService,
    ApiProductServiceClient,
    ApiProductServiceClientByClientId
} from '../../../../config/service';
import { ProductServiceInterface } from '../../../../interface';
import './style.scss';
import { useParams } from 'react-router';

const TreeComponent = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { id: clientId } = useParams();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    const [data, setData] = useState<ProductServiceInterface[]>([]);
    const [dataOfPsc, setDataOfPsc] = useState<any>([]);
    const [foundElements, setFoundElements] = useState<any>([]);

    useEffect(() => {
        if (data && dataOfPsc) {
            if (dataOfPsc.length > 0) {
                const found: any = [];
                for (let i: number = 0; i < dataOfPsc.length; i++) {
                    const item = dataOfPsc[i];
                    const { productService } = item;
                    if (productService) {
                        const elementSection = data.find(
                            item => item.id === productService.id
                        );
                        const objectSection = {
                            ...elementSection
                        };
                        found.push(objectSection);
                    }
                }
                if (found[0].id) {
                    setFoundElements(found);
                }
            }
        }
    }, [dataOfPsc, data]);

    useEffect(() => {
        if (foundElements.length > 0) {
            for (let i: number = 0; i < foundElements.length; i++) {
                const found = foundElements[i];
                delete found.productService;
            }
            setSelectedOptions(foundElements);
        }
    }, [foundElements]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        const response = await GetData(ApiProductService, HTTP_METHODS.GET);
        const { data, status, message } = response;
        const sortedData = [...data].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        setData(sortedData);
        if (!status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const CallDataOfProductServiceClient = async () => {
        if (clientId) {
            const response = await GetData(
                ApiProductServiceClientByClientId(clientId),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
            setDataOfPsc(data);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the COMPANY list
     */
    useEffect(() => {
        CallData();
        CallDataOfProductServiceClient();
    }, []);

    const HandleCheckboxChange = (event: any, data: any) => {
        const checkSelected = event.target.checked;
        AsignData(data, checkSelected);
    };

    const AsignData = (data, checkSelected) => {
        const finalArray = [...selectedOptions];
        const newData = { ...data };
        if (checkSelected) {
            finalArray.push(newData);
        } else {
            const index = finalArray.findIndex(item => item.id === newData.id);
            finalArray.splice(index, 1);
        }
        setSelectedOptions(finalArray);
    };

    const SaveConfig = async () => {
        const arrayTosend = selectedOptions.map(item => {
            const object = {
                client: clientId,
                productService: item.id
            };
            return object;
        });
        const payload = {
            data: arrayTosend,
            client: clientId
        };
        const response = await GetData(
            ApiProductServiceClient,
            HTTP_METHODS.POST,
            payload
        );
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    return (
        <div>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <ul className='settings-tree'>
                {data.map(level1 => {
                    return (
                        <li className={`level1`} key={level1.id}>
                            <button>{level1.name}</button>
                            <input
                                type='checkbox'
                                id={`section-${level1.id}`}
                                name='appsections'
                                value={level1.id}
                                checked={selectedOptions.find(
                                    item => item.id === level1.id
                                )}
                                onChange={e => {
                                    HandleCheckboxChange(e, level1);
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
            <button
                className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                onClick={SaveConfig}
            >
                Grabar
            </button>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default TreeComponent;
