import { SetCookie, ReadCookie, RemoveCookie } from './Cookie';
import { DeleteStorage } from './Storage';
import { DeleteDB } from './IndexedDb';

const dbName = 'prymadb';

export const appCookie = 'bzero-2-auth';

export const SetAuthCookie = (data: any) => {
    SetCookie(data, appCookie);
};

export const GetAuthCookie = () => {
    return ReadCookie(appCookie);
};

export const Logout = () => {
    DeleteDB({ dbName });
    RemoveCookie(appCookie);
    RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
    RemoveCookie(process.env.REACT_APP_PUBLIC_CC_DATA_COOKIE);
    // DeleteAllStorage();
    const ListStorageItems = Object.keys(localStorage).filter(
        key => !key.includes('sessions')
    );
    ListStorageItems.forEach(element => {
        DeleteStorage(element);
    });
    if (
        window.location.pathname !== '/login' &&
        !window.location.pathname.includes('/registration/invite/')
    ) {
        window.location.href = '/login';
    }
};
