import { ProgramInterface } from './program.interface';
import { PropertyUnitInterface } from './property-unit.interface';
import { ConfigReportInterface } from './config-reports.interface';

export interface PropertyInterface {
    id: string;
    name: string;
    address: string;
    rut: string;
    image: string;
    paCode: string;
    csCode: string;
    procedures: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    programs?: ProgramInterface[];
    propertyUnits: PropertyUnitInterface[];
    configReports: ConfigReportInterface[];
    clientData?: any;
}

export interface PropertyFormCreateInterface {
    name: string;
    address: string;
    rut: string;
}

export const PropertyFormValues: PropertyFormCreateInterface = {
    name: '',
    address: '',
    rut: ''
};
