import '../create-calendar.scss';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, ConfigProvider, DatePicker, Input, Select } from 'antd';
import { useCallApi } from '../../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../../../../config/hooks/useToast';
import { UserStoreInterface } from '../../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../../store/hooks';
import {
    ApiPropertyByUserRole,
    ApiGestor,
    ApiMaterial,
    ApiCalendar
} from '../../../../../../config/service';
import { NormalizeName } from '../../../../../../config/utils/Utilities';

interface Props {
    onClose: () => void;
}

const CreateEvent = ({ onClose }: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile];
    const [recurrent, setRecurrent] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [properties, setProperties] = useState<any[]>([]);
    const [gestors, setGestors] = useState<any[]>([]);
    const [materials, setMaterials] = useState<any[]>([]);
    const [weeks, setWeeks] = useState<number | null>(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: 'Retiros por gestor',
        property: null,
        gestor: null,
        materials: [],
        startDate: null,
        hour: null,
        recurrent: false,
        recurrentDetail: null,
        clientUser: null
    });

    const onChange = (value, field) => {
        setShowAlert(false);
        setFormData(prevData => ({ ...prevData, [field]: value }));
    };

    const handleWeeksChange = (value: number) => {
        setWeeks(value);
    };

    // Función para manejar el clic en un día de la semana
    const handleDayClick = (day: string) => {
        setSelectedDays(prevSelectedDays => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(
                    selectedDay => selectedDay !== day
                );
            } else {
                return [...prevSelectedDays, day];
            }
        });
    };

    // Array de días con identificadores únicos para martes y miércoles
    const daysOfWeek = [
        { id: 'L', label: 'L' },
        { id: 'M1', label: 'M' }, // Martes
        { id: 'M2', label: 'M' }, // Miércoles
        { id: 'J', label: 'J' },
        { id: 'V', label: 'V' },
        { id: 'S', label: 'S' },
        { id: 'D', label: 'D' }
    ];

    const CallData = async () => {
        const { data: clients } = await GetData(
            ApiPropertyByUserRole(userData.id),
            HTTP_METHODS.GET,
            {},
            [selectedRole.id]
        );
        const properties = clients?.flatMap(client => client.properties) || [];
        const uniqueProperties: any = Array.from(
            new Map(
                properties.map(property => [property.id, property])
            ).values()
        );
        const formattedProperties = uniqueProperties
            .map(property => ({
                value: property.id,
                label: property.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        setProperties(formattedProperties);

        const { data: gestors } = await GetData(ApiGestor, HTTP_METHODS.GET);
        const formattedGestors = gestors
            .map(gestor => ({
                value: gestor.id,
                label: gestor.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        setGestors(formattedGestors);

        const { data: materials } = await GetData(
            ApiMaterial,
            HTTP_METHODS.GET
        );
        const formattedMaterials = materials
            .map(material => ({
                value: material.id,
                label: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            className='calendarRequest__main__form__material-label-img'
                            src={`/svg-icons/material-icons/${NormalizeName(
                                material.name
                            )}.svg`}
                            onError={e => {
                                const target = e.target as HTMLImageElement;
                                target.src =
                                    '/svg-icons/material-icons/general.svg';
                            }}
                        />
                        {material.name} - {material.codeLer}
                    </div>
                )
            }))
            .sort((a, b) =>
                a.label.props.children[1].localeCompare(
                    b.label.props.children[1]
                )
            );
        setMaterials(formattedMaterials);
    };

    const validateForm = () => {
        const {
            title,
            description,
            property,
            gestor,
            materials,
            startDate,
            hour
        } = formData;
        if (
            !title ||
            !description ||
            !property ||
            !gestor ||
            !materials.length ||
            !startDate ||
            !hour
        ) {
            setShowAlert(true);
            return false;
        } else {
            setShowAlert(false);
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        // Preparar la data para enviar
        const eventData = {
            ...formData,
            recurrent,
            recurrentDetail: recurrent
                ? JSON.stringify({
                      days: selectedDays.map(day => {
                          switch (day) {
                              case 'L':
                                  return 1;
                              case 'M1':
                                  return 2; // Martes
                              case 'M2':
                                  return 3; // Miércoles
                              case 'J':
                                  return 4;
                              case 'V':
                                  return 5;
                              case 'S':
                                  return 6;
                              case 'D':
                                  return 7;
                              default:
                                  return null;
                          }
                      }),
                      weeks: weeks ?? 1 // Si no se seleccionan semanas, asumimos 1 como predeterminado
                  })
                : null
        };

        try {
            /* await GetData(ApiCalendarCreate, HTTP_METHODS.POST, eventData); */
            setShowAlert(false);
            const { status, message } = await GetData(
                ApiCalendar,
                HTTP_METHODS.POST,
                eventData
            );
            if (status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                setConfigToast(ConfigToast);
            } else {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
            // Aquí puedes resetear el formulario si es necesario
        } catch (error) {
            console.error('Error al crear el evento', error);
        }
    };

    useEffect(() => {
        CallData();
    }, []);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <div className='calendarRequest__main__menu subsubtitle'>
                <CloseOutlined
                    className='calendarRequest__main__menu__close'
                    onClick={onClose}
                    rev={''}
                />
                <hr />
            </div>
            <div className='calendarRequest__main__header title'>
                <div className='calendarRequest__main__header__title'>
                    <div className='calendarRequest__main__header__title__circle' />
                    <div className='calendarRequest__main__header__title__text'>
                        Crear evento de retiro por gestor
                    </div>
                </div>
            </div>
            <div className='calendarRequest__main__text subtitle'>
                <form
                    className='calendarRequest__main__form'
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <label className='calendarRequest__main__form__label'>
                        Título:
                    </label>
                    <Input
                        placeholder='Ingresa un título'
                        count={{
                            show: true,
                            max: 10
                        }}
                        value={formData.title}
                        onChange={e => onChange(e.target.value, 'title')}
                    />
                    <label className='calendarRequest__main__form__label'>
                        Descripción:
                    </label>
                    <Input
                        placeholder='Ingresa una descripción'
                        count={{
                            show: true,
                            max: 200
                        }}
                        value={formData.description}
                        onChange={e => onChange(e.target.value, 'description')}
                    />
                    <label className='calendarRequest__main__form__label'>
                        Inmueble:
                    </label>
                    <Select
                        showSearch
                        placeholder='Selecciona un inmueble'
                        optionFilterProp='label'
                        onChange={value => onChange(value, 'property')}
                        options={properties}
                    />
                    <label className='calendarRequest__main__form__label'>
                        Gestor:
                    </label>
                    <Select
                        showSearch
                        placeholder='Selecciona un gestor'
                        optionFilterProp='label'
                        onChange={value => onChange(value, 'gestor')}
                        options={gestors}
                    />
                    <label className='calendarRequest__main__form__label'>
                        Materiales:
                    </label>
                    <Select
                        mode='multiple'
                        showSearch
                        placeholder='Selecciona los materiales'
                        optionFilterProp='label'
                        onChange={value => onChange(value, 'materials')}
                        options={materials}
                        filterOption={(input, option) =>
                            option?.label?.props?.children[1]
                                ?.toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    />
                    <label className='calendarRequest__main__form__label'>
                        Fecha:
                    </label>
                    <div className='calendarRequest__main__form__date'>
                        <DatePicker
                            className='calendarRequest__main__form__date__date-picker'
                            onChange={date => onChange(date, 'startDate')}
                        />
                        <Select
                            className='calendarRequest__main__form__date__hour'
                            showSearch
                            placeholder='hr'
                            optionFilterProp='label'
                            onChange={value => onChange(value, 'hour')}
                            options={[
                                { value: 'am', label: 'am' },
                                { value: 'pm', label: 'pm' }
                            ]}
                        />
                    </div>
                    <div className='calendarRequest__main__form__recurrency'>
                        <div className='calendarRequest__main__form__recurrency__checkbox'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Checkbox: {
                                            colorPrimary: `#C4C3C5`,
                                            colorBorder: `#C4C3C5`,
                                            colorPrimaryHover: `#C4C3C5`
                                        }
                                    }
                                }}
                            >
                                <Checkbox
                                    checked={recurrent}
                                    onClick={() => setRecurrent(!recurrent)}
                                />
                            </ConfigProvider>
                            <span className='calendarRequest__main__form__recurrency__checkbox__label'>
                                Se repite
                            </span>
                        </div>
                        {recurrent && (
                            <div className='calendarRequest__main__form__recurrency__details'>
                                Cada
                                <Select
                                    className='calendarRequest__main__form__recurrency__details__weeks'
                                    showSearch
                                    variant='borderless'
                                    placeholder='1'
                                    optionFilterProp='label'
                                    onChange={handleWeeksChange}
                                    options={[
                                        { value: 1, label: '1' },
                                        { value: 2, label: '2' }
                                    ]}
                                />
                                semanas, los días
                                <div className='calendarRequest__main__form__recurrency__details__days'>
                                    {daysOfWeek.map(day => (
                                        <button
                                            key={day.id}
                                            className={`calendarRequest__main__form__recurrency__details__days__item ${
                                                selectedDays.includes(day.id)
                                                    ? 'day-active'
                                                    : ''
                                            }`}
                                            onClick={e => {
                                                e.preventDefault();
                                                handleDayClick(day.id);
                                            }}
                                        >
                                            {day.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    {showAlert && (
                        <div className='calendarRequest__main__form__alert'>
                            Por favor, completa todos los campos.
                        </div>
                    )}
                    <div className='calendarRequest__main__form__btns'>
                        <button
                            className={`button button-body--cancel subtitle`}
                            /* onClick={() => Cancel(resetForm)} */
                        >
                            Cancelar
                        </button>
                        <button
                            className={`button button-body--create subtitle`}
                            /*  htmlType='submit' */
                        >
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default CreateEvent;
