import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@mui/material/Icon';
import { tableData } from './data/columns_table';
import Filters from './filters/filters';
import useFilters from './hooks/useFilters';
import { TableInterface } from './interface/table.interface';
import './table.scss';

const RenderFilters = (keysFilter, regs, currentPage, ClickPagination) => {
    const { dataForm, setDataForm } = useFilters(
        keysFilter,
        regs,
        currentPage,
        ClickPagination
    );
    return (
        dataForm && <Filters value={dataForm} controller={setDataForm as any} />
    );
};

const TableComponent = ({
    data,
    headers,
    showHeaders,
    showFilters,
    keysFilter,
    showPagination,
    actionsButtons,
    addsActions,
    callback,
    addClass
}: TableInterface) => {
    const {
        regXpage,
        /* listCountPages, */
        emptyData /* 
        filesPageTxt,
        filesPageTxtConnector,
        regsPageTxt */
    } = tableData;
    const [registers, setRegisters] = useState<object[]>(data);
    const [registersCurrentPage, setRegistersCurrentPage] = useState<object[]>(
        []
    );
    const [registersFilter, setRegistersFilter] = useState<object[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [registersXPages, setRegistersXPages] = useState<number>(regXpage);
    const [listFilters, setListFilters] = useState<string[]>([]);
    const navigate = useNavigate();
    //  Asign filters to the filters state
    useEffect(() => {
        if (showFilters && !keysFilter) {
            const el: string = headers[0].dataField ? headers[0].dataField : '';
            const arrEmptyFilters: string[] = [el];
            setListFilters(arrEmptyFilters);
        } else {
            setListFilters(keysFilter ?? []);
        }
    }, [keysFilter]);

    useEffect(() => {
        if (!showPagination) {
            setRegistersXPages(1000);
        }
    }, [showPagination]);

    useEffect(() => {
        ClickPagination(1, registers);
    }, [registersXPages]);

    //  The number of pages is assigned
    const setPagesPagination = dataPages => {
        const rxp: number = registersXPages;
        const countData = dataPages.length;
        const resto: number = countData % rxp;
        let coc: number = Math.trunc(countData / rxp);
        if (resto > 0) {
            coc += 1;
        }
        /* setPages(coc); */
        const arrpages: number[] = [];
        for (let e = 0; e < coc; e += 1) {
            arrpages.push(e + 1);
        }
    };

    //  Assign data to be filtered - Set data of the current, initial and final page for pagination
    const AsignData = (page: number, dataAsign) => {
        setCurrentPage(page);
        const ini: number = page * registersXPages - registersXPages;
        const fin: number = ini + registersXPages;
        const arr: number[] = [];
        for (let i = ini; i < fin; i += 1) {
            arr.push(i);
        }
        const dataBackup: object[] = dataAsign;
        const ArrFilter: object[] = [];
        for (let x = 0; x < dataBackup.length; x += 1) {
            const exist = arr.indexOf(x);
            if (exist >= 0) {
                ArrFilter.push(dataBackup[x]);
            }
        }
        setRegistersFilter(dataAsign);
    };

    //  Handler for click events in pagination
    const ClickPagination = (page, dataPagination = registersCurrentPage) => {
        setPagesPagination(dataPagination);
        AsignData(page, dataPagination);
    };

    useEffect(() => {
        if (data) {
            setRegisters(data);
            setRegistersCurrentPage(data);
        }
    }, [data]);

    //  Every time the data is assigned to the state, the data for pagination is initialized.
    useEffect(() => {
        if (registers.length >= 0) {
            ClickPagination(currentPage);
        }
    }, [registers]);

    const TypeText = (text: string, type: string = '') => {
        if (type === 'upperCase') {
            return text.toUpperCase();
        }
        if (type === 'lowerCase') {
            return text.toLowerCase();
        }
        if (text === 'pascalCase') {
            return text.replace(
                /\w+/g,
                w => w[0].toUpperCase() + w.slice(1).toLowerCase()
            );
        }
        return text;
    };

    const handleRowClick = item => {
        if (callback) {
            callback(item);
        }
        const currentPath = window.location.pathname;
        const basePathEdit = currentPath.split('/edit')[0];
        const basePathCreate = currentPath.split('/create')[0];
        let newItemUrl = '';
        if (currentPath.split('/edit')[1]) {
            newItemUrl = `${basePathEdit}/edit/${item.id}`;
        } else {
            newItemUrl = `${basePathCreate}/edit/${item.id}`;
        }
        navigate(newItemUrl);
    };

    return (
        <div className={`tablecontainer subtitle ${addClass}`}>
            {showFilters && (
                <div className='tablecontainer__filters'>
                    {RenderFilters(
                        listFilters,
                        registers,
                        currentPage,
                        ClickPagination
                    )}
                </div>
            )}
            <div className='tablecontainer__table-wrapper'>
                <table className='tablecontainer__tblcomponent table table-hover'>
                    <thead className='tablecontainer__tblcomponent__thead'>
                        <tr className='tbl__editable__thead__tr'>
                            {showHeaders &&
                                headers.map((item, index) => (
                                    <th
                                        key={`colhead-${index}-${item.text}`}
                                        className={`tbl__editable__thead__tr__th ${item.text}-default subtitle`}
                                    >
                                        {item.options ? (
                                            <span
                                                className='tbl__editable__thead__tr__th__text'
                                                style={{
                                                    width: '100%',
                                                    display: 'block',
                                                    textAlign:
                                                        item.options.align,
                                                    fontWeight: item.options
                                                        .strong
                                                        ? 'bold'
                                                        : 'normal'
                                                }}
                                            >
                                                {item.text}
                                            </span>
                                        ) : (
                                            <span className='tbl__editable__thead__tr__th__text'>
                                                {item.text}
                                            </span>
                                        )}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody className='tablecontainer__tblcomponent__tbody'>
                        {registersFilter.length > 0 ? (
                            registersFilter.map((item: any, index) => {
                                return (
                                    <tr
                                        key={`TBL_BODY_${index}`}
                                        className='tablecontainer__tblcomponent__tbody__tr'
                                        onClick={() => handleRowClick(item)}
                                    >
                                        {headers.map((header, idx) => {
                                            const nameCol =
                                                header.dataField === null
                                                    ? 'id'
                                                    : header.dataField;
                                            const childs = nameCol.split('.');
                                            return (
                                                <td
                                                    key={`TBL_BODY_${nameCol}_${idx}`}
                                                    className='tablecontainer__tblcomponent__tbody__tr__td'
                                                >
                                                    {header.options ? (
                                                        <span
                                                            className='comercial_table__containertable__table__tbody__tr__td__span'
                                                            style={{
                                                                width: '100%',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    header
                                                                        .options
                                                                        .align,
                                                                color: header
                                                                    .options
                                                                    .color,
                                                                fontWeight:
                                                                    header
                                                                        .options
                                                                        .strong
                                                                        ? 'bold'
                                                                        : 'normal'
                                                            }}
                                                        >
                                                            {TypeText(
                                                                childs.length >
                                                                    1
                                                                    ? item[
                                                                          childs[0]
                                                                      ]
                                                                        ? item[
                                                                              childs[0]
                                                                          ][
                                                                              childs[1]
                                                                          ]
                                                                        : ''
                                                                    : item[
                                                                          nameCol
                                                                      ],
                                                                header.options
                                                                    .textType
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {' '}
                                                            {header.renderFunction
                                                                ? header.renderFunction(
                                                                      childs.length >
                                                                          1
                                                                          ? item[
                                                                                childs[0]
                                                                            ] &&
                                                                            item[
                                                                                childs[0]
                                                                            ] !==
                                                                                null
                                                                              ? item[
                                                                                    childs[0]
                                                                                ][
                                                                                    childs[1]
                                                                                ]
                                                                              : ''
                                                                          : item[
                                                                                nameCol
                                                                            ]
                                                                  )
                                                                : childs.length >
                                                                  1
                                                                ? item[
                                                                      childs[0]
                                                                  ] &&
                                                                  item[
                                                                      childs[0]
                                                                  ] !== null
                                                                    ? item[
                                                                          childs[0]
                                                                      ][
                                                                          childs[1]
                                                                      ]
                                                                    : ''
                                                                : item[
                                                                      nameCol
                                                                  ]}{' '}
                                                        </span>
                                                    )}
                                                </td>
                                            );
                                        })}
                                        {actionsButtons && (
                                            <td className='icons-section'>
                                                {actionsButtons.onEdit && (
                                                    <EditIcon
                                                        titleAccess='Edit'
                                                        className='tablecontainer__tblcomponent__tbody__tr__td__icon'
                                                        onClick={() => {
                                                            actionsButtons.onEdit?.(
                                                                item.id
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {actionsButtons.onDelete && (
                                                    <DeleteIcon
                                                        titleAccess='Delete'
                                                        className='tablecontainer__tblcomponent__tbody__tr__td__icon'
                                                        onClick={() => {
                                                            actionsButtons.onDelete?.(
                                                                item.id
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {addsActions?.map(
                                                    (
                                                        itemAction,
                                                        indexAction
                                                    ) => {
                                                        return (
                                                            <Icon
                                                                key={`addsaction_${indexAction}`}
                                                                title={
                                                                    itemAction.title
                                                                }
                                                                onClick={() => {
                                                                    itemAction.action?.(
                                                                        item.id
                                                                    );
                                                                }}
                                                                className='tablecontainer__tblcomponent__tbody__tr__td__icon_dynamic'
                                                            >
                                                                {
                                                                    itemAction.icon
                                                                }
                                                            </Icon>
                                                        );
                                                    }
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className='tablecontainer__tblcomponent__tbody__tr'>
                                <td
                                    className='comercial_table__containertable__table__tbody__tr__td'
                                    colSpan={headers.length}
                                >
                                    <p> {emptyData} </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableComponent;
