import { useState } from 'react';
import { Globals } from './Constants';

interface FilterDetailInterface {
    keys: string[];
    valuesProperty: string[];
    valuesPropertyUnits?: string[];
    valuesDate?: string[];
}

export interface FilterInterface {
    tables: string[];
    filters: FilterDetailInterface;
    dateFilterRange?: string[];
}

const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB;

export const DeleteDB = async ({ dbName }) => {
    indexedDB.deleteDatabase(dbName);
};

export function IDB(dbName) {
    const [status, setStatus] = useState({
        LoadingData: false,
        ObtainedData: {}
    });

    const DeleteCollection = DeleteDB;

    const CreateCollection = (tables, version) => {
        if (indexedDB) {
            const request = indexedDB.open(dbName, version);
            request.onupgradeneeded = () => {
                const db = request.result;
                for (const table of tables) {
                    if (!db.objectStoreNames.contains(table.tableName)) {
                        const objectStore = db.createObjectStore(
                            table.tableName,
                            {
                                keyPath: table.id
                            }
                        );
                        const keyStructured = table.indexes;

                        for (const element of keyStructured) {
                            const kpth = element.split(',');
                            if (kpth.length <= 1) {
                                objectStore.createIndex(element, element, {
                                    unique: false
                                });
                            } else {
                                objectStore.createIndex(element, kpth, {
                                    unique: false
                                });
                            }
                        }
                    }
                }
            };
        }
    };

    const AddDataToDb = async (tables, version, callback) => {
        for (const table of tables) {
            const dbPromise = indexedDB.open(dbName, version);
            dbPromise.onsuccess = () => {
                const db = dbPromise.result;
                const objectStore = db
                    .transaction(table.tableName, 'readonly')
                    .objectStore(table.tableName);
                const existingDataRequest = objectStore.getAll();

                existingDataRequest.onsuccess = () => {
                    interface OBSinterface extends IDBObjectStore {
                        onsuccess?: () => void;
                    }
                    const customerObjectStore: OBSinterface = db
                        .transaction(table.tableName, 'readwrite')
                        .objectStore(table.tableName);

                    table.data.forEach(result => {
                        if (table.tableName === 'data-center') {
                            result.total = result.total / 1000;
                        }
                        customerObjectStore.add(result);
                    });

                    customerObjectStore.onsuccess = () => {
                        db.close();
                    };
                    callback();
                };
            };
        }
    };

    const GetAllDataDb = async ({
        tables,
        dateFilterRange
    }: FilterInterface) => {
        setStatus({ ...status, LoadingData: true });
        const dbPromise = indexedDB.open(dbName, 2);
        dbPromise.onsuccess = () => {
            const db = dbPromise.result;
            const obtainedData: any = {};
            tables.forEach((tableName, index) => {
                const customerObjectStore = db
                    .transaction([tableName], 'readonly')
                    .objectStore(tableName);
                const registers = customerObjectStore.getAll();
                registers.onsuccess = () => {
                    obtainedData[tableName] = registers.result;
                    if (index === tables.length - 1) {
                        for (const key in obtainedData) {
                            obtainedData[key] = FilterByDate(
                                obtainedData[key],
                                Globals.IDBDefaultDateFilterKey,
                                dateFilterRange
                            );
                        }
                        setStatus({
                            LoadingData: false,
                            ObtainedData: obtainedData
                        });
                    }
                };
            });
        };
    };

    function GetDataDb({
        tables,
        filters: { keys, valuesProperty, valuesPropertyUnits, valuesDate },
        dateFilterRange
    }: FilterInterface) {
        setStatus({ ...status, LoadingData: true });
        const dbPromise = indexedDB.open(dbName, 2);
        const dates = valuesDate ?? [];

        dbPromise.onsuccess = () => {
            const db = dbPromise.result;
            const obtainedData: any = {};

            tables.forEach((tableName, index) => {
                const customerObjectStore = db
                    .transaction([tableName], 'readonly')
                    .objectStore(tableName);

                const registers = customerObjectStore.getAll();
                registers.onsuccess = () => {
                    obtainedData[tableName] = registers.result;
                    if (index === tables.length - 1) {
                        for (const key in obtainedData) {
                            const newValues = [...valuesProperty, ...dates];
                            const newValuesUnits = valuesPropertyUnits
                                ? [...valuesPropertyUnits, ...dates]
                                : [];
                            const filteredArrayProp = obtainedData[key].filter(
                                elem => newValues.includes(elem.propertyId)
                            );
                            const filteredArrayUnits = obtainedData[key].filter(
                                elem =>
                                    newValuesUnits.includes(elem.propertyUnit)
                            );
                            obtainedData[key] = FilterByDate(
                                filteredArrayProp.concat(filteredArrayUnits),
                                Globals.IDBDefaultDateFilterKey,
                                dateFilterRange
                            );
                        }

                        setStatus({
                            LoadingData: false,
                            ObtainedData: obtainedData
                        });
                    }
                };
            });
        };
    }

    return {
        DeleteCollection,
        CreateCollection,
        AddDataToDb,
        GetAllDataDb,
        GetDataDb,
        ...status
    };
}

const FilterByDate = (data, key, values) => {
    let dataFilter;
    if (values && values.length >= 2) {
        dataFilter = values;
    } else {
        dataFilter = Globals.IDBDefaultDateFilterRange;
    }
    const filter = data.filter(item => {
        return (
            parseInt(item[key]) >= parseInt(dataFilter[0]) &&
            parseInt(item[key]) <= parseInt(dataFilter[1])
        );
    });
    return filter;
};
