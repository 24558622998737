export const Config = {
    options: {
        tooltips: {
            mode: 'index'
        },
        devicePixelRatio: 35
        /* animation: {
            delay: context => {
                let delay = 0;
                if (context.type === 'data') {
                    delay =
                        context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            }
        } */
    },
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Bar Chart - Stacked'
        },
        legend: {
            display: false,
            position: 'bottom',
            align: 'start',
            labels: {
                boxWidth: 20,
                fontColor: '#7327b2',
                fontSize: 12,
                padding: 20,
                usePointStyle: true
            }
        },

        tooltip: {
            mode: 'index',
            backgroundColor: 'rgb(255, 255, 255)',
            titleColor: 'rgb(162 162 163)', // Color del texto del título del tooltip
            bodyColor: 'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
            footerColor: 'rgb(162 162 163)',
            titleFont: { size: 10 }, // Cambiar el tamaño de letra del título
            bodyFont: { size: 10 },
            footerFont: { size: 10 },
            padding: 6,
            boxPadding: 3,
            usePointStyle: true,
            callbacks: {
                title: tooltipItem => {
                    const date = tooltipItem[0].label;
                    return `Fecha: ${date.substring(0, 4)}-${date.substring(
                        4,
                        6
                    )}`;
                },
                label: tooltipItem => {
                    const value = parseFloat(tooltipItem.raw);
                    if (value > 0) {
                        return `${
                            tooltipItem.dataset.label
                        } ${value.toLocaleString('es-CL', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1
                        })}`;
                    }
                    return '';
                },
                footer: tooltipItems => {
                    let sum = 0;
                    tooltipItems.forEach(function (tooltipItem) {
                        if (tooltipItem.dataset.label !== 'Índice BZero') {
                            sum += parseFloat(tooltipItem.parsed.y);
                        }
                    });
                    return `Total: ${sum.toLocaleString('es-CL', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                    })}`;
                }
                /*
                footer: tooltipItem => {
                    return `Peso: ${parseFloat(
                        tooltipItem[0].raw
                    ).toLocaleString('es', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                    })} Kg`;
                } */
                // afterFooter: (tooltipItem) => {
                //     return `Peso: ${(parseFloat(tooltipItem[0].raw)).toFixed(2)} Kg`;
                // },
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            display: true,
            ticks: {
                font: {
                    size: 10
                }
            },
            title: {
                display: true,
                align: 'center',
                text: 'Meses',
                font: {
                    size: 10,
                    weight: 'bold',
                    family: 'Roboto'
                }
            },
            grid: {
                lineWidth: 0
            }
        },
        y: {
            stacked: true,
            display: true,
            border: {
                color: 'rgb(196 195 197)',
                width: 4
            },
            grid: {
                lineWidth: 0.5
            }
        },
        y1: {
            min: 0,
            max: 100,
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            ticks: {
                stepSize: 20,
                font: {
                    size: 10
                }
            },
            title: {
                display: true,
                align: 'center',
                text: 'Índice BZero [%]',
                font: {
                    size: 10,
                    weight: 'bold',
                    family: 'Roboto'
                }
            },
            grid: {
                drawOnChartArea: false
            },
            border: {
                color: 'rgb(3 218 197)',
                width: 4
            }
        }
    }
};

export const AddTicks = {
    ticks: {
        callback: function (value, index, ticks) {
            if (ticks.length === 0) {
                return `${value}`;
            }

            const maxValue = ticks.reduce(
                (max, tick) => (tick.value > max.value ? tick : max),
                ticks[0]
            );

            if (maxValue.value > 1000) {
                return `${(value / 1000).toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1
                })}`;
            } else {
                return `${value.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1
                })}`;
            }
        },
        font: {
            size: 10
        }
    },
    title: {
        display: true,
        text: 'Peso',
        font: {
            size: 10,
            weight: 'bold',
            family: 'Roboto'
        }
    }
};
