import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CryptoJS from 'crypto-js';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Wrapper } from '../../components/styles';
//  <--INTERFACE--> //
import {
    UserFomLoginInterface,
    LoginFormValues,
    UserAuthResponseInterface
} from '../../interface';
//  <--CONFIG--> //
import { RemoveCookie } from '../../config/utils/Cookie';
import { appCookie, SetAuthCookie } from '../../config/utils/Auth';
import { SaveStorage } from '../../config/utils/Storage';
import { ApiLogin, ApiPropertyByUserRole } from '../../config/service/index';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useToastHook } from '../../store/slices/toast/toast.hook';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../config/hooks/useCallApi';
//  <--COMPONENTS--> //
import FormBuilder from '../../components/formBuilder/formBuilder';
import LogoComponent from '../../components/logo/logo';
// import GoogleAuth from './auth-components/google';
// import MicrosoftAuth from './auth-components/microsoft';
// import OauthWrapper from './auth-components/oauthWrapper';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import {
    setUserData,
    setIsLoged,
    setDeffaultProfile
} from '../../store/slices/users';
import { setPropertyData } from '../../store/slices/property';
import { setClientData } from '../../store/slices/client';
import { setUnitData } from '../../store/slices/unit';
//  <--OTHERS--> //
import { lang } from '../langs';
import { FormLogin } from './form';
import { ROUTES } from '../../config/routes/routesList';
import '../../scss/global/global.scss';
import './login.scss';
import { GetValidationErrorCode } from '../../config/errors/get-validation-error';

const LoginPage = () => {
    const { ToastConfig } = useToastHook();
    const { Colors } = useToast();
    const dispatch = useAppDispatch();
    const { preferences, isLoged }: UserStoreInterface = useAppSelector(
        state => state.users
    );

    const defaultLang: string = preferences.lang;
    const { id } = useParams();
    const key = defaultLang;
    const navigate = useNavigate();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        [key as keyof typeof lang]: {
            pageLogin: {
                forgotPassword,
                subTitle,
                btnLoginTxt,
                btnCreateUserText,
                errorNotInvitation
            }
        }
    } = lang;
    const [inviteId, setInviteId] = useState<string>('');

    const ClearSiteData = async () => {
        RemoveCookie(appCookie);
        RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
        RemoveCookie(process.env.REACT_APP_PUBLIC_CC_DATA_COOKIE);
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setInviteId(id);
        }
    }, [id]);

    useEffect(() => {
        if (isLoged) {
            // navigate(ROUTES.panel);
        } else {
            ClearSiteData();
        }
    }, []);

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    /**
     * @description
     * @function OnLogin is a function that is called when the form is submitted
     * @param loginData is a object that contains the data of the form
     */
    const OnLogin = async (loginData: UserFomLoginInterface) => {
        loginData.inviteId = inviteId;
        try {
            const secret: string = process.env.REACT_APP_PUBLIC_JWT_SECRET
                ? process.env.REACT_APP_PUBLIC_JWT_SECRET
                : '';
            const ciphertext = CryptoJS.AES.encrypt(
                JSON.stringify(loginData),
                secret
            ).toString();
            const response = await GetData(
                ApiLogin,
                HTTP_METHODS.GET,
                {},
                ciphertext
            );
            if (response.status) {
                const data: UserAuthResponseInterface = response.data;
                const { token, userData } = data;
                delete userData.properties;
                dispatch(setUserData(userData));
                dispatch(setIsLoged(true));
                SaveStorage(
                    userData,
                    process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE
                );
                SaveStorage(0, process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE);
                SaveStorage('[]', process.env.REACT_APP_PUBLIC_USER_SECTIONS);
                dispatch(setDeffaultProfile(0));
                SetAuthCookie({ token });
                const sessions = localStorage.getItem('sessions');
                if (sessions) {
                    const count = parseInt(sessions) + 1;
                    localStorage.setItem('sessions', JSON.stringify(count));
                } else {
                    localStorage.setItem('sessions', '0');
                }
                const rolesIds = userData.roles.map(item => {
                    return item.id;
                });
                const uniqueRoles = rolesIds.filter(onlyUnique);
                const response2 = await GetData(
                    ApiPropertyByUserRole(userData.id),
                    HTTP_METHODS.GET,
                    {},
                    uniqueRoles
                );
                const clientPropertyData = response2.data.flatMap(
                    client => client.properties
                );
                const allPropertyData = clientPropertyData.map(property => ({
                    ...property,
                    propertyUnits: (
                        property?.units ||
                        property?.propertyUnits ||
                        []
                    ).map(unit => ({
                        ...unit,
                        property
                    }))
                }));
                const units = response2.data.flatMap(client =>
                    client.units ? client.units : []
                );
                dispatch(setPropertyData(allPropertyData));
                SaveStorage(
                    allPropertyData,
                    process.env.REACT_APP_PUBLIC_USER_PROPERTIES
                );
                dispatch(setUnitData(units));
                SaveStorage(units, process.env.REACT_APP_PUBLIC_USER_UNITS);
                dispatch(setClientData(response2.data));
                SaveStorage(
                    response2.data,
                    process.env.REACT_APP_PUBLIC_USER_CLIENTS
                );
                navigate(ROUTES.panel);
            } else {
                ToastConfig({
                    message: GetValidationErrorCode(response.code),
                    color: Colors.Error
                });
            }
        } catch (err) {
            console.log('err=>', err);
        }
    };

    const Create = () => {
        if (inviteId) {
            navigate(ROUTES.register + inviteId);
        } else {
            ToastConfig({
                message: errorNotInvitation,
                color: Colors.Warning
            });
        }
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        buttonsWrapperClass:
            'app-d-flex__center childs_100 app-flexd__c_reverse',
        ok: {
            controller: OnLogin,
            text: btnLoginTxt,
            class: 'bzero_btn_primary_color'
        },
        cancel: {
            controller: Create,
            text: btnCreateUserText,
            class: 'bzero_btn_white'
        }
    };

    // const successGoogle = async data => {
    //     const payload = {
    //         email: data.email,
    //         password: '',
    //         oauth: { isGoogle: true, isMs: null, oauthId: data.sub }
    //     };
    //     await OnLogin(payload);
    // };

    // const errorGoogle = () => {
    //     console.log('Login Failed');
    // };

    // const successMs = async data => {
    //     const payload = {
    //         email: data.account.username,
    //         password: '',
    //         oauth: {
    //             isGoogle: null,
    //             isMs: true,
    //             oauthId: data.account.localAccountId
    //         }
    //     };
    //     await OnLogin(payload);
    // };

    // const errorMs = () => {
    //     console.log('Login Failed');
    // };

    return (
        <div className='login_page'>
            <div className='login_page__item left-login'>
                <div className='login_page__item__title'>
                    <p className='_login__title'> {subTitle} </p>
                </div>
            </div>
            <div className='login_page__item right-login'>
                <Container className='_login'>
                    <LogoComponent image='PRYMA_LOGO_BLANCO.png' />
                    <Wrapper className='_login__wrapper'>
                        <FormBuilder
                            initialValues={LoginFormValues}
                            buttons={FormButtons}
                            lang={defaultLang}
                            formStructure={FormLogin}
                            styles={{ backgroundColor: 'transparent' }}
                            config={{
                                noLabels: true,
                                noClearButton: false
                            }}
                        />
                        <hr />
                        {/* {                        <OauthWrapper>
                            <GoogleAuth
                                onSuccess={successGoogle}
                                onError={errorGoogle}
                                textType='signin'
                            />
                            <MicrosoftAuth
                                onSuccess={successMs}
                                onError={errorMs}
                            />
                        </OauthWrapper>} */}
                        <hr />
                        <Link
                            className='link-recover-pass'
                            to={ROUTES.reqChangePass}
                        >
                            {forgotPassword}
                        </Link>
                    </Wrapper>
                    {LoadingData && <LoaderElement />}
                </Container>
            </div>
            <div className='logo-inferior'>
                <LogoComponent image='BZERO_LOGO_BLANCO.svg' />
            </div>
        </div>
    );
};

export default LoginPage;
