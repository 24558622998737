import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { api, apiFiles } from './request';
import { HTTP_METHODS } from './constants';
import { ApiResponseInterface } from 'src/interface';
import Loader from './loader';
import { GetAuthCookie } from '../../utils/Auth';
import { GetStorage } from '../../utils/Storage';
import { useAppSelector } from '../../../store/hooks';
import { ApplicationInterface } from '../../../store/slices/application/application.interface';
const KeySaveRequest = 'request-to-send';

interface State {
    LoadingData: boolean;
    OnError?: Error;
    Data?: any;
}

const defaultResponse = {
    code: 400,
    status: false,
    message: '',
    data: {}
};

interface PropsLoader {
    inBackGround?: boolean;
}

export function useCallApi(
    path: string = '',
    method: string = HTTP_METHODS.GET
) {
    const { connection }: ApplicationInterface = useAppSelector(
        state => state.application
    );
    const [status, setStatus] = useState<State>({
        LoadingData: false
    });

    useEffect(() => {
        // console.log(status)
    }, [status]);

    const VerifyHttpRequestPendings = () => {
        const exist = localStorage.getItem(KeySaveRequest);
        const successStatus: boolean[] = [];
        if (exist) {
            const pendings = JSON.parse(exist);
            pendings.forEach(async element => {
                const { status: successRequest } = await GetData(
                    element.path,
                    HTTP_METHODS.POST,
                    element.data
                );
                successStatus.push(successRequest);
            });
            const resSavePendings = successStatus.indexOf(false);
            if (resSavePendings <= -1) {
                localStorage.setItem(KeySaveRequest, JSON.stringify([]));
            }
        }
    };

    useEffect(() => {
        if (connection) {
            VerifyHttpRequestPendings();
        }
    }, [connection]);

    const SaveRequest = (path: string, data = {}) => {
        const object = { path, data };
        let list: any = [];
        const exist = localStorage.getItem(KeySaveRequest);
        if (exist) {
            list = JSON.parse(exist);
        }
        const listLng: number = list.length;
        list.push({ ...object, id: listLng + 1 });
        localStorage.setItem(KeySaveRequest, JSON.stringify(list));
    };

    const GetData = async (
        path: string,
        method: string,
        data = {},
        dataHeaders = {},
        isFile: boolean = false,
        showLoader: boolean = true
    ): Promise<ApiResponseInterface> => {
        try {
            if (path === '') {
                setStatus({ LoadingData: false, Data: {} });
                return defaultResponse;
            }
            if (showLoader) {
                setStatus({ LoadingData: true, Data: {} });
            }
            if (!connection) {
                // setStatus({ LoadingData: false, Data: {} });
                if (method === HTTP_METHODS.POST) {
                    SaveRequest(path, data);
                    return { ...defaultResponse, message: 'Sin conexión' };
                }
                //
            }
            const { token } = await GetAuthCookie();
            const lang = await GetStorage(
                process.env.REACT_APP_PUBLIC_STORE_LANG
            );
            const response: AxiosResponse<any> = isFile
                ? await apiFiles({
                      method,
                      path,
                      token,
                      lang,
                      data,
                      dataHeaders
                  })
                : await api({
                      method,
                      path,
                      token,
                      lang,
                      data,
                      dataHeaders
                  });
            if (response.request.responseURL.indexOf('auth/validate') <= -1) {
                setStatus({ LoadingData: false, Data: response.data });
            }
            return response.data;
        } catch (error: any) {
            setStatus({ LoadingData: false, OnError: error });
            const newData = error.response ? error.response.data : error;
            return {
                ...defaultResponse,
                ...newData
            };
        }
    };

    const LoaderElement = ({ inBackGround = false }: PropsLoader) => {
        return !inBackGround ? <Loader /> : <></>;
    };

    useEffect(() => {
        if (path !== '' || path != null) {
            const data = {};
            GetData(path, method, data);
        }
    }, [path]);

    return { ...status, GetData, LoaderElement };
}
