import { PropertyInterface } from './property.interface';
import { PropertyUnitCategoryInterface } from './property-unit-category.interface';

export interface PropertyUnitInterface {
    id: string;
    name: string;
    uacode: string;
    uucode: string;
    floor: string;
    surface: string;
    category: string;
    property: PropertyInterface;
    propertyUnitCategory: PropertyUnitCategoryInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface PropertyUnitFormCreateInterface {
    name: string;
    floor: string;
    surface: string;
    category: string;
    property: string;
}

export const PropertyUnitFormValues: PropertyUnitFormCreateInterface = {
    name: '',
    floor: '',
    surface: '',
    category: '',
    property: ''
};
