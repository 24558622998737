//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import { lang } from '../../../langs';
// import RolesClientTable from './roles-client-table';
import { ReactNode } from 'react';
import ClientBusinessTableComponent from './table-list';
// import { useParams } from 'react-router';
interface Props {
    children?: ReactNode;
    updates?: boolean;
}
const PropertiesPermissionsPage = ({ children, updates }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageClient: { title, propertiesList }
            // detailsTitle
        }
    } = lang;
    // const { id: clientId } = useParams();

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Productos y Servicios'
            ></SubNavBar>
            <div className='settings_client'>
                <Header title={{ name: title }} icon='GestorsIconB.svg' />
                <h4>{propertiesList}</h4>
                <ClientBusinessTableComponent />
                {children}
            </div>
        </>
    );
};

export default PropertiesPermissionsPage;
