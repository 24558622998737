import { ClientInterface } from './client.interface';

export interface ClientRoleInterface {
    id: string;
    name: string;
    email: string;
    functionType: string;
    client: ClientInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface ClientRoleFormCreateInterface {
    name: string;
    functionType: string;
    client?: string;
}

export const ClientRoleFormValues: ClientRoleFormCreateInterface = {
    name: '',
    functionType: ''
};
