import React, { useImperativeHandle, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './alert.scss';

interface OkInterface {
    func: any;
    param?: string;
}

interface Props {
    title?: string;
    message?: string;
    okButtonText?: string;
    okButtonController?: OkInterface;
    cancelButtonText?: string;
    show?: boolean;
}

export const Alert = React.forwardRef(function Alert(props: Props, ref) {
    const [open, setOpen] = React.useState(props.show);
    const [dataProps, setDataProps] = useState<Props>({ ...props });

    useImperativeHandle(ref, () => ({
        changeAlert(nextProps: Props) {
            setOpen(true);
            setDataProps(nextProps);
        }
    }));

    const CloseAlert = () => {
        setOpen(false);
    };

    const OnOkClick = () => {
        dataProps.okButtonController?.func(dataProps.okButtonController?.param);
        setOpen(false);
    };

    return (
        <>
            {dataProps !== undefined && open === true && (
                <div className='alert_container'>
                    <div className='alert_container__main'>
                        <div className='alert_container__main__container'>
                            <div className='alert_container__main__title title'>
                                <span> {dataProps.title} </span>

                                <CloseIcon
                                    className='alert_container__main__title__close'
                                    onClick={CloseAlert}
                                />
                                <hr />
                            </div>
                            <div className='alert_container__main__text subtitle'>
                                <p> {dataProps.message} </p>
                            </div>
                        </div>
                        <div className='alert_container__main__buttons app-d-flex__center childs_uniform'>
                            {dataProps.okButtonText && (
                                <button
                                    className='bzero_btn_success subsubtitle'
                                    onClick={OnOkClick}
                                >
                                    {dataProps.okButtonText}
                                </button>
                            )}

                            {dataProps.cancelButtonText && (
                                <button
                                    className='bzero_btn_danger subsubtitle'
                                    onClick={CloseAlert}
                                >
                                    {dataProps.cancelButtonText}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});
