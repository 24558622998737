import { createSlice } from '@reduxjs/toolkit';
import { PermissionStoreInitialvalues } from './initialValues';

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: PermissionStoreInitialvalues,
    reducers: {
        setProductAndService: (state, action) => {
            const data = action.payload;
            state.permissionsData.productAndService = data;
        }
    }
});

export const { setProductAndService } = permissionSlice.actions;

export default permissionSlice.reducer;
