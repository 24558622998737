import '../../settings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import MaterialKindTableComponent from '../components/material-kind-table';
import FormBuilder from '../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    MaterialKindFormCreateInterface,
    MaterialKindFormValues
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import { ApiMaterialKind, ApiMaterialKindId } from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import { FormCreateMaterialKind } from './form';
import '../../../../scss/global/global.scss';

const MaterialKindCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageMaterialKind: { createTitle, editTitle },
            pageMaterial: {
                title,
                adminTitle,
                option1,
                option2,
                detailTitle,
                deleteTitle,
                deleteMessage,
                newTitle
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<MaterialKindFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [manageBy, setManageBy] = useState('Type-Materials');
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(MaterialKindFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    const rgbToHex = rgb => {
        const regex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
        const matches = rgb.match(regex);
        if (matches) {
            const r = parseInt(matches[1]);
            const g = parseInt(matches[2]);
            const b = parseInt(matches[3]);
            return `#${((r << 16) | (g << 8) | b)
                .toString(16)
                .padStart(6, '0')}`;
        }
        return rgb;
    };

    const hexToRgb = (hex: string): string => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
            return r.concat(r, g, g, b, b);
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            const r: number = parseInt(result[1], 16);
            const g: number = parseInt(result[2], 16);
            const b: number = parseInt(result[3], 16);
            return `rgb(${r}, ${g}, ${b})`;
        }
        return hex;
    };

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiMaterialKindId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const newData = {
            ...data,
            color: rgbToHex(data.color)
        };
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: MaterialKindFormCreateInterface) => {
        values.color = hexToRgb(values.color);
        const response = id
            ? await GetData(
                  ApiMaterialKindId(entityId),
                  HTTP_METHODS.PATCH,
                  values
              )
            : await GetData(ApiMaterialKind, HTTP_METHODS.POST, values);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a MATKI
     * @param id is a string that contains the id of the MATKI to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a MATKI
     * @param id is a string that contains the id of the MATKI to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(
            ApiMaterialKindId(id),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate('/panel/material-kind');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/materialsIcon.svg'
                kind='settings'
                option='Materials'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/materials' }}
                    icon='MaterialsIconB.svg'
                    subtitle={{ name: id ? editTitle : createTitle }}
                />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='MatkiPage/MateriaKindList'
                    >
                        <div className='settings__grid-container__item__title'>
                            <div className='settings__grid-container__item__title__section1 title'>
                                {adminTitle}{' '}
                            </div>
                            <div className='settings__grid-container__item__title__section2 title'>
                                <Link
                                    className={`settings__grid-container__item__title__section2__item left ${
                                        manageBy === 'Materials'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => setManageBy('Materials')}
                                    to='/panel/materials'
                                >
                                    {option1}
                                </Link>
                                <Link
                                    className={`settings__grid-container__item__title__section2__item right ${
                                        manageBy !== 'Materials'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() =>
                                        setManageBy('Materials-Type')
                                    }
                                    to='/panel/material-kind'
                                >
                                    {option2}
                                </Link>
                            </div>
                        </div>
                        <MaterialKindTableComponent refresh={updates} />
                        <div
                            className='settings__grid-container__item__content'
                            id='MatkiPage/MateriaKindList/NewMateriaKind'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/material-kind/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div
                        className='settings__grid-container__item_right'
                        id='MatkiPage/MateriaKindDetail'
                    >
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            {readyForm && (
                                <FormBuilder
                                    initialValues={
                                        entity?.description
                                            ? entity
                                            : MaterialKindFormValues
                                    }
                                    buttons={FormButtons}
                                    lang={defaultLang}
                                    formStructure={FormCreateMaterialKind}
                                    focusOn={fieldRef}
                                    config={{
                                        noClearButton: !!entity?.name,
                                        editButton: !!entity?.name && detailPage
                                    }}
                                />
                            )}
                            {id && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        id='MatkiPage/MateriaKindDetail/Delete'
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                    <button
                                        id='MatkiPage/MateriaKindDetail/Edit'
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                        onClick={Edit}
                                    >
                                        {editTitle}
                                    </button>
                                </div>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                </div>
            </div>
        </>
        /* <Container>
            <ToastElement ref={toastManagerRef} />
            <GoBack />
            <PageTitle title={entity?.description ? editTitle : createTitle} />
            <Wrapper>
                {readyForm && (
                    <FormBuilder
                        initialValues={
                            entity?.description
                                ? entity
                                : MaterialKindFormValues
                        }
                        buttons={FormButtons}
                        lang={defaultLang}
                        formStructure={FormCreateMaterialKind}
                        focusOn={fieldRef}
                        config={
                            entity?.description
                                ? { noClearButton: true }
                                : { noClearButton: false }
                        }
                    />
                )}
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container> */
    );
};

export default MaterialKindCreate;
