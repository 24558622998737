import { createSlice } from '@reduxjs/toolkit';
import { MaterialDefault } from './initialValues';

export const materialSlice = createSlice({
    name: 'material',
    initialState: MaterialDefault,
    reducers: {
        setMaterialData: (state, action) => {
            const data = action.payload;
            state.materialData = data;
        }
    }
});

export const { setMaterialData } = materialSlice.actions;

export default materialSlice.reducer;
