export interface SelectInterface {
    id: string;
    name: string;
}

export const PermissionsFunctionTypeList: SelectInterface[] = [
    { id: 'VISUALIZACIÓN', name: 'VISUALIZACIÓN' },
    { id: 'OPERACIÓN', name: 'OPERACIÓN' },
    { id: 'ADMINISTRACIÓN', name: 'ADMINISTRACIÓN' }
];
