import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import ButtonBody from '../../../components/button/button';
import QRTableComponent from './qr-table';
//  <--INTERFACE--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
//  <--OTHERS--> //
import { lang } from '../../langs';
const Company = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageQR: { title, adminTitle, newTitle }
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/QrIcon.svg' // TODO change svg
                kind='settings'
                option='Qr'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: title }} icon='QrIconB.svg' />
                <div className='settings__grid-container' id='QrPage/QRList'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <QRTableComponent />
                        <div
                            className='settings__grid-container__item__content'
                            id='QrPage/QRList/New'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/qr/create'
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company;
