import { FormBuilderFields } from '../../../components/formBuilder/form-builder.interface';
import { ICON_ASIDE_VALUES } from '../constants';

export const FormCreate: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Path',
        fieldNameId: 'path',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el path',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Código',
        fieldNameId: 'code',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el Código',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Ícono',
        fieldNameId: 'icon',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el Ícono',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Menú principal',
        fieldNameId: 'isIcon',
        fieldType: 'select',
        elements: ICON_ASIDE_VALUES,
        fieldPlaceholder: 'Seleccione una opción',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Menú settings',
        fieldNameId: 'isAside',
        fieldType: 'select',
        elements: ICON_ASIDE_VALUES,
        fieldPlaceholder: 'Seleccione una opción',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
