import { FormBuilderFields } from '../../../components/formBuilder/form-builder.interface';

export const FormCreateQR: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese nombre del QR',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Descripción',
        fieldNameId: 'description',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la descripción del QR',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 70,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Tipo',
        fieldNameId: 'type',
        fieldType: 'select',
        fieldPlaceholder: 'Seleccione un tipo',
        elements: [
            { id: 'external-url', name: 'URL Externa' },
            { id: 'internal-url', name: 'URL Interna' },
            { id: 'pdf', name: 'PDF' },
            { id: 'photo', name: 'Foto' }
        ],
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'URL Externa',
        fieldNameId: 'external-url',
        depends: 'type',
        dependsValues: ['URL Externa'],
        fieldType: 'string',
        fieldPlaceholder: 'Ingrese la URL de página a redirigir',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 7089,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'URL Interna',
        fieldNameId: 'internal-url',
        depends: 'type',
        dependsValues: ['URL Interna'],
        fieldType: 'url',
        fieldPlaceholder: 'Ingrese la URL de página a redirigir',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 7089,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'PDF',
        fieldNameId: 'pdf',
        depends: 'type',
        dependsValues: ['PDF'],
        fieldType: 'file',
        fieldPlaceholder: 'Ingrese el PDF',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Foto',
        fieldNameId: 'photo',
        depends: 'type',
        dependsValues: ['Foto'],
        fieldType: 'file',
        fieldPlaceholder: 'Ingrese la foto',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    }
];
