//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import { lang } from '../../../langs';
import TreeComponent from './TreeComponent';

const ProductServicesClientPage = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageProductServiceClient: { title }
            // detailsTitle
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Productos y Servicios del cliente'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: `${title}` }} icon='GestorsIconB.svg' />
                <div className='settings__grid-container'>
                    <TreeComponent />
                </div>
            </div>
        </>
    );
};

export default ProductServicesClientPage;
