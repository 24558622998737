import { PermissionStoreInterface } from './permissions.interface';

export const PermissionStoreInitialvalues: PermissionStoreInterface = {
    permissionsData: {
        permissionSettings: {
            component: {
                id: '',
                name: ''
            },
            function: {
                id: '',
                name: ''
            },
            section: {
                id: '',
                name: ''
            }
        },
        productAndService: {
            id: '',
            name: ''
        }
    }
};
