import './style.scss';
const MainLoader = () => {
    return (
        <div className='MainLoader'>
            <div className='wrapper'></div>
        </div>
    );
};

export default MainLoader;
