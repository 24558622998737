//  <--COMPONENTS--> //
// import { useEffect } from 'react';

import './control-panel.scss';
import NotificationsCard from '../../components/controlPanelComponents/notificationsCard/notificationscard';
import GarbageControlCard from '../../components/controlPanelComponents/garbageControl/garbageControl';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
//  <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { GetStorage } from '../../config/utils/Storage';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const ControlPanel = () => {
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='control-panel-container'>
                {appSections.some(item => item.name.includes('Sinader')) && (
                    <Link
                        id='CtrlpPage/CompleteForm'
                        className='control-panel-container__btn subtitle'
                        to='/panel/sinader'
                    >
                        Completar planilla de cierre mensual
                    </Link>
                )}
                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            PRÓXIMAMENTE
                        </span>
                    }
                    color='#fff'
                >
                    <NotificationsCard />
                </Tooltip>
                {!userData.roles[0].name.includes('Gestor') && (
                    <Tooltip
                        title={
                            <span className='main_menu__grid-container__tooltip'>
                                PRÓXIMAMENTE
                            </span>
                        }
                        color='#fff'
                    >
                        <GarbageControlCard />
                    </Tooltip>
                )}
            </div>
        </>
    );
};

export default ControlPanel;
