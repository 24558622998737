import '../../settings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
//  <--COMPONENTS-->
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import RoleTableComponent from '../components/role-table';
import FormBuilder from '../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../components/button/button';
import RoleAccess from '../role-access';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useToastHook } from '../../../../store/slices/toast/toast.hook';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    AppSectionInterface,
    RoleFormCreateInterface,
    RoleFormValues
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiUserRole,
    ApiUserRoleId,
    ApiUserRoleIdWithoutSections,
    ApiUserRoleAdd
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import { FormCreateRole } from './form';
import '../../../../scss/global/global.scss';

const RoleCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ToastConfig } = useToastHook();
    const { Colors /* , goPreviousPage */ } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageRole: {
                title,
                createTitle,
                editTitle,
                errorMessage,
                detailTitle,
                adminTitle,
                deleteMessage,
                deleteTitle,
                newTitle,
                accessTitle,
                asociatedSections,
                unasociatedSections
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<RoleFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [asociatedList, setAsociatedList] = useState<AppSectionInterface[]>(
        []
    );
    const [unassociatedList, setUnassociatedList] = useState<
        AppSectionInterface[]
    >([]);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(RoleFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiUserRoleId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: RoleFormCreateInterface) => {
        try {
            setSuccessCleanForm(false);
            const response = id
                ? await GetData(
                      ApiUserRoleId(entityId),
                      HTTP_METHODS.PATCH,
                      values
                  )
                : await GetData(ApiUserRole, HTTP_METHODS.POST, values);
            const { message, data, status } = response;
            if (status) {
                setSuccessCleanForm(true);
                ToastConfig({
                    message,
                    color: Colors.Success
                });
                setReadyForm(false);
                CallDataEdit();
                setUpdates(true);
            } else {
                ToastConfig({
                    message: data.message,
                    color: Colors.Error
                });
            }
        } catch (err) {
            ToastConfig({ message: errorMessage, color: Colors.Error });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit,
            text: 'Guardar'
        },
        cancel: {
            controller: FocusOnInput,
            text: 'Cancel'
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiUserRoleId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ToastConfig({
                message,
                color: Colors.Success
            });
            navigate('/panel/role');
        } else {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    /**
     * @description function that is executed when the detail button is pressed
     * @function ShowMaterials is a function that opens the detail modal and calls the function that returns the ROLE data
     * @param id is a string that contains the id of the ROLE to be consulted
     */
    const ShowAccess = async (id: string) => {
        setSelectedRole(id);
        const response = await GetData(
            ApiUserRoleIdWithoutSections(id),
            HTTP_METHODS.GET
        );
        const {
            data: { asocciated, unassociated }
        } = response;
        setAsociatedList(asocciated);
        setUnassociatedList(unassociated);
        setShowDetails(true);
    };

    const updatePermissions = async (sectionId: string, action: string) => {
        try {
            const dataSend = {
                roleId: selectedRole,
                sectionId,
                action
            };
            const response = await GetData(
                ApiUserRoleAdd,
                HTTP_METHODS.POST,
                dataSend
            );
            const { data, status, message } = response;
            if (status) {
                const {
                    data: { asocciated, unassociated }
                } = response;
                setAsociatedList(asocciated);
                setUnassociatedList(unassociated);
                ToastConfig({
                    message,
                    color: Colors.Success
                });
            } else {
                ToastConfig({ message: data.message, color: Colors.Error });
            }
        } catch (err) {
            ToastConfig({ message: errorMessage, color: Colors.Error });
        }
    };

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/rolesIconB.svg'
                kind='settings'
                option='Roles'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/role' }}
                    icon='RolesIconB.svg'
                    subtitle={{ name: id ? editTitle : createTitle }}
                />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='RolesPage/RoleList'
                    >
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <RoleTableComponent refresh={updates} />
                        <div
                            className='settings__grid-container__item__content'
                            id='RolesPage/RoleList/RoleCreate'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/role/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            {readyForm && (
                                <FormBuilder
                                    initialValues={
                                        entity?.name ? entity : RoleFormValues
                                    }
                                    buttons={FormButtons}
                                    lang={defaultLang}
                                    formStructure={FormCreateRole}
                                    focusOn={fieldRef}
                                    config={{
                                        noClearButton: !!entity?.name,
                                        editButton: !!entity?.name && detailPage
                                    }}
                                    onSuccessCleanForm={successCleanForm}
                                />
                            )}
                            {id && detailPage && (
                                <>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            id='RolesPage/RoleDetail/ShowPermissions'
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={async () =>
                                                await ShowAccess(id)
                                            }
                                        >
                                            Ver permisos
                                        </button>
                                    </div>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            id='RolesPage/RoleDetail/Delete'
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={Delete}
                                        >
                                            {deleteTitle}
                                        </button>
                                        <button
                                            id='RolesPage/RoleDetail/Update'
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                            onClick={Edit}
                                        >
                                            {editTitle}
                                        </button>
                                    </div>
                                </>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                </div>
            </div>
            {showDetails && (
                <RoleAccess
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    detailsTitle={accessTitle}
                    asociatedTitle={asociatedSections}
                    unasociatedTitle={unasociatedSections}
                    asociatedAccessList={asociatedList}
                    unassociatedAccessList={unassociatedList}
                    SuccessSave={updatePermissions}
                />
            )}
        </>
    );
};

export default RoleCreate;
