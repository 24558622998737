import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import FormBuilder from '../../../../../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToast } from '../../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../../config/hooks/useCallApi/constants';
//  import { useFormBuilder } from '../../../config/hooks/useFormBuilder';
//  <--INTERFACE--> //
import {
    InvitationsFormCreateInterface,
    InvitationsFormValues,
    PropertyInterface,
    PropertyUnitInterface,
    GestorInterface
} from '../../../../../../interface';
//  <--SERVICES--> //
import {
    ApiGestorUserGestors,
    ApiInvitation,
    ApiInvitationId,
    ApiPropertyByUserRole
} from '../../../../../../config/service';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../../store/hooks';
//  <--OTHERS--> //
import { FormCreateCalendarRequest } from './form';
/* import { lang } from '../../langs'; */

interface Props {
    onSuccessSave?: (status: boolean) => void;
}

export const CalendarRequestCreateComponent = ({ onSuccessSave }: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences, userData, defaultProfile }: UserStoreInterface =
        useAppSelector(state => state.users);
    const defaultLang: string = preferences.lang;
    /* const key = defaultLang; */
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const { roles } = userData;
    const selectedRole = roles[defaultProfile];
    /* const {
        [key as keyof typeof lang]: {
            pageInvites: {
                buttonSendInvitation,
                deleteTitle,
                copyTitle,
                deleteMessage,
                copyMessage
            }
        }
    } = lang; */
    const fieldRef = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<InvitationsFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [gestorsList, setGestorsList] = useState<GestorInterface[]>([]);
    const [propertiesList, setPropertiesList] = useState<PropertyInterface[]>(
        []
    );

    const CallElements = async () => {
        const response = await GetData(
            ApiGestorUserGestors(userData.id),
            HTTP_METHODS.GET
        );
        setGestorsList(
            [...response.data].sort((a, b) => a.name.localeCompare(b.name))
        );

        const response2 = await GetData(
            ApiPropertyByUserRole(userData.id),
            HTTP_METHODS.GET,
            {},
            [selectedRole.id]
        );
        const properties = response2?.data?.flatMap(item => item?.properties);
        setPropertiesList(
            [...properties].sort((a, b) => a.name.localeCompare(b.name))
        );
    };

    useEffect(() => {
        CallElements();
    }, []);

    useEffect(() => {
        if (propertiesList && propertiesList?.length > 0) {
            const uniquePropertiesList = propertiesList.filter(
                (item, index, self) =>
                    index === self.findIndex(t => t.id === item.id)
            );
            FormCreateCalendarRequest[0].elements = uniquePropertiesList;
        }
    }, [propertiesList]);

    useEffect(() => {
        if (gestorsList && gestorsList?.length > 0) {
            FormCreateCalendarRequest[2].elements = gestorsList;
        }
    }, [gestorsList]);

    const handleUnits = (units: PropertyUnitInterface[]) => {
        FormCreateCalendarRequest[6].elements = [...units]?.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        FormCreateCalendarRequest[7].elements = [...units]?.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    };

    const handleClientBusiness = (clientBusiness: {
        properties: PropertyInterface[];
        gestors: GestorInterface[];
    }) => {
        FormCreateCalendarRequest[4].elements = [
            ...clientBusiness.properties
        ]?.sort((a, b) => a.name.localeCompare(b.name));
        FormCreateCalendarRequest[5].elements = [
            ...clientBusiness.properties
        ]?.sort((a, b) => a.name.localeCompare(b.name));
        FormCreateCalendarRequest[8].elements = [
            ...clientBusiness.gestors
        ]?.sort((a, b) => a.name.localeCompare(b.name));
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(InvitationsFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiInvitationId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const newData = { ...data, role: data.role.id };
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async values => {
        const newValues: InvitationsFormCreateInterface = {
            email: values.email,
            role: values.role,
            relatedId: {
                client:
                    values.client_multiple ??
                    (values.client_business
                        ? values.client_business.split(',')
                        : []),
                property: values.property
                    ? values.property.split(',')
                    : values.property_multiple ?? [],
                unit: values.unit
                    ? values.unit.split(',')
                    : values.unit_multiple ?? [],
                gestor: values.gestor ? values.gestor.split(',') : []
            }
        };
        const response = await GetData(
            ApiInvitation,
            HTTP_METHODS.POST,
            newValues
        );
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            onSuccessSave?.(true);
            navigate('/panel/invitations');
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit,
            text: 'Enviar'
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a MATKI
     * @param id is a string that contains the id of the MATKI to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = 'Elinar';
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a MATKI
     * @param id is a string that contains the id of the MATKI to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(
            ApiInvitationId(id),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            navigate('/panel/invitations');
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const CopyToClipboard = () => {
        navigator.clipboard.writeText(
            `https://pryma.app/registration/invite?id=${id}`
        );
        ConfigToast.text = 'copiar';
        ConfigToast.backColor = Colors.Success;
        setConfigToast(ConfigToast);
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            {readyForm && (
                <FormBuilder
                    initialValues={
                        entity?.email ? entity : InvitationsFormValues
                    }
                    buttons={FormButtons}
                    lang={defaultLang}
                    formStructure={FormCreateCalendarRequest}
                    focusOn={fieldRef}
                    config={{
                        noClearButton: !!id,
                        editButton: !!id
                    }}
                    unitsOnSelect={handleUnits}
                    propertiesOnSelect={handleClientBusiness}
                />
            )}
            {id && (
                <div className='settings__grid-container__item_right__content__btn'>
                    <button
                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                        onClick={Delete}
                    >
                        Eliminar
                    </button>
                    <button
                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                        onClick={CopyToClipboard}
                    >
                        Copiar
                    </button>
                </div>
            )}
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default CalendarRequestCreateComponent;
