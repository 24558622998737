import './components.scss';
import { NormalizeName } from '../../../../../config/utils/Utilities';

interface TableInterface {
    data?: any;
}

const TableSummary = ({ data }: TableInterface) => {
    const totalMaterials = () => {
        if (data.length > 0) {
            return data?.reduce(
                (total, item) => parseInt(total) + Number(item.total),
                0
            );
        } else {
            return 0;
        }
    };

    return (
        <table className='summary-table'>
            <thead className='summary-table__thead'>
                <tr className='summary-table__thead__tr'>
                    <th className='summary-table__thead__tr__th'>Residuo</th>
                    <th className='summary-table__thead__tr__th'>
                        Cantidad [kg]
                    </th>
                    <th className='summary-table__thead__tr__th'>
                        % del total
                    </th>
                    <th className='summary-table__thead__tr__th'>
                        Transportista
                    </th>
                    <th className='summary-table__thead__tr__th'>
                        Disposición Final
                    </th>
                </tr>
            </thead>
            <tbody className='summary-table__tbody'>
                {data.length > 0 &&
                    data?.map((item, index) => (
                        <tr className='summary-table__tbody__tr' key={index}>
                            <td className='summary-table__tbody__tr__td'>
                                <div
                                    className='summary-table__tbody__tr__td__container'
                                    style={{
                                        backgroundColor: item.materialColor,
                                        color: '#fff'
                                    }}
                                >
                                    <img
                                        className='summary-table__tbody__tr__td__container__img'
                                        src={`/svg-icons/material-icons/${NormalizeName(
                                            item.materialName
                                        )}.svg`}
                                    />
                                    <div className='summary-table__tbody__tr__td__container__text'>
                                        {item.materialName}
                                    </div>
                                </div>
                            </td>
                            <td className='summary-table__tbody__tr__td'>
                                <div className='summary-table__tbody__tr__td__container'>
                                    {(item.total / 1000).toLocaleString(
                                        'es-CL',
                                        {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 1
                                        }
                                    )}
                                </div>
                            </td>
                            <td className='summary-table__tbody__tr__td'>
                                <div className='summary-table__tbody__tr__td__container'>
                                    {(
                                        (item.total / totalMaterials()) *
                                        100
                                    ).toLocaleString('es-CL', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1
                                    })}
                                </div>
                            </td>
                            <td className='summary-table__tbody__tr__td'>
                                <div className='summary-table__tbody__tr__td__container'>
                                    {item.gestorName}
                                </div>
                            </td>
                            <td className='summary-table__tbody__tr__td'>
                                <div className='summary-table__tbody__tr__td__container'>
                                    {item.finalDisposal}
                                </div>
                            </td>
                        </tr>
                    ))}
                <tr className='summary-table__tbody__tr'>
                    <td className='summary-table__tbody__tr__td'>
                        <div className='summary-table__tbody__tr__td__container-total'>
                            TOTAL
                        </div>
                    </td>
                    <td className='summary-table__tbody__tr__td'>
                        <div className='summary-table__tbody__tr__td__container'>
                            {(totalMaterials() / 1000).toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1
                            })}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TableSummary;
