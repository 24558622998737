import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateUser: FormBuilderFields[] = [
    {
        label: 'Correo electrónico',
        fieldNameId: 'email',
        fieldType: 'email',
        fieldPlaceholder: 'Ingrese su correo',
        autoComplete: 'off',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 50,
                state: true,
                message: 'Máximo 50 caracteres'
            },
            email: {
                state: true,
                message: 'Ingrese un email válido'
            }
        }
    },
    {
        label: 'Rol',
        fieldNameId: 'role',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un rol',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Cliente(s)',
        fieldNameId: 'client_multiple',
        depends: 'role',
        dependsValues: ['General Admin'],
        fieldType: 'select multiple',
        elements: [],
        fieldPlaceholder: 'Seleccione los clientes',
        validations: {
            type: 'array',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Cliente (opcional)',
        fieldNameId: 'client_business',
        depends: 'role',
        dependsValues: [
            'Property Admin',
            'Cleaning Staff',
            'Unit Admin',
            'Unit User',
            'Gestor User',
            'Gestor Admin',
            'Waste Admin'
        ],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el cliente',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        depends: 'role',
        dependsValues: ['Unit User', 'Unit Admin'],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un inmueble',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Inmueble(s)',
        fieldNameId: 'property_multiple',
        depends: 'role',
        dependsValues: ['Property Admin', 'Cleaning Staff', 'Waste Admin'],
        fieldType: 'select multiple',
        elements: [],
        fieldPlaceholder: 'Seleccione los inmuebles',
        validations: {
            type: 'array',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad',
        fieldNameId: 'unit',
        depends: 'role',
        dependsValues: ['Unit User'],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione una unidad',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad(es)',
        fieldNameId: 'unit_multiple',
        depends: 'role',
        dependsValues: ['Unit Admin'],
        fieldType: 'select multiple',
        elements: [],
        fieldPlaceholder: 'Seleccione las unidades',
        validations: {
            type: 'array',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Gestor',
        fieldNameId: 'gestor',
        depends: 'role',
        dependsValues: ['Gestor Admin', 'Gestor User'],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un gestor',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }

    /* {
        label: 'Cliente asociado',
        fieldNameId: 'clientAsociated',
        depends: 'role',
        dependsValues: ['Property Admin', 'Cleaning Staff'],
        fieldType: 'select',
        deffaultValues: true,
        elements: [
            {
                id: 'No',
                name: 'No'
            },
            {
                id: 'Si',
                name: 'Si'
            }
        ],
        fieldPlaceholder: 'Cliente asociado',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }, */
];

export const FormViewUser: FormBuilderFields[] = [
    {
        label: 'Correo electrónico',
        fieldNameId: 'email',
        fieldType: 'email',
        fieldPlaceholder: 'Ingrese su correo',
        autoComplete: 'off',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 50,
                state: true,
                message: 'Máximo 50 caracteres'
            },
            email: {
                state: true,
                message: 'Ingrese un email válido'
            }
        }
    },
    {
        label: 'Rol',
        fieldNameId: 'role',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un rol',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Propiedad',
        fieldNameId: 'property',
        depends: 'role',
        dependsValues: [
            'General Admin',
            'Property Admin',
            'Unit Admin',
            'Unit User',
            'Cleaning Staff'
        ],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione una propiedad',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
