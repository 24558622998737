//  <--COMPONENTS--> //
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import Header from '../../components/header/header';
import ButtonBody from '../../components/button/button';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
import { lang } from '../langs';
import TableList from './table-list';
import { Level1ServicesPagePath } from './constants';
import { ReactNode, useEffect, useState } from 'react';
interface Props {
    children?: ReactNode;
    updates?: boolean;
}
const PermissionsPagePage = ({ children, updates }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pagePernissionsSettingsAppSection: { title, buttonNew, tableTitle }
            // detailsTitle
        }
    } = lang;
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        if (updates) {
            setReload(updates);
            setTimeout(() => {
                setReload(false);
            }, 50);
        }
    }, [updates]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Configuración de Permisos'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: title }} icon='GestorsIconB.svg' />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='ScfPage/SectionsList'
                    >
                        <div className='settings__grid-container__item__title title'>
                            {tableTitle}
                        </div>
                        <TableList refresh={reload} />
                        <div
                            className='settings__grid-container__item__content'
                            id='ScfPage/SectionsList/NewSection'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={buttonNew}
                                path={`/panel/${Level1ServicesPagePath}/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default PermissionsPagePage;
