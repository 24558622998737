//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//  <--REDUX--> //
import './settings.scss';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import { GetStorage } from '../../config/utils/Storage';
import { useAppSelector } from '../../store/hooks';
import { AppSectionInterface } from '../../interface';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const Settings = () => {
    const { userData } = useAppSelector(state => state.users);
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const [sections, setSections] = useState<AppSectionInterface[]>([]);

    useEffect(() => {
        setSections(appSections);
    }, [appSections]);

    return (
        <>
            <SubNavBar
                type='settings'
                title='Configuraciones'
                icon='SettingsIconB.svg'
            ></SubNavBar>
            <div className='settings-grid-container' id='SettiPage/QuickAccess'>
                {sections.map(item => {
                    return (
                        item.isAside &&
                        item.state === 1 && (
                            <Link
                                id={`SettiPage/QuickAccess/${item.code}`}
                                style={{ textDecoration: 'none' }}
                                to={`../${item.path}`}
                                key={item.id}
                            >
                                {' '}
                                <div className='settings-grid-container__optionItem'>
                                    {item.icon !== null ? (
                                        <div className='settings-grid-container__optionItem__imgContainer'>
                                            <img
                                                className='settings-grid-container__optionItem__imgContainer__img'
                                                src={`/svg-icons/settings/${item.icon}.svg`}
                                            />
                                        </div>
                                    ) : (
                                        item.name
                                    )}
                                    <div className='settings-grid-container__optionItem__textContainer'>
                                        <span className='settings-grid-container__optionItem__textContainer__title subtitle'>
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        )
                    );
                })}
            </div>
        </>
    );
};

export default Settings;
