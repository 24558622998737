import '../../../settings.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../../components/header/header';
import RequirementsTableComponent from './requirements-table';
import ButtonBody from '../../../../../components/button/button';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--SERVICES--> //
import { ApiFontId } from '../../../../../config/service';
//  <--INTERFACE--> //
import {
    FontFormValues,
    FontFormCreateInterface
} from '../../../../../interface';
import { lang } from '../../../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';

const Requirements = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { GetData } = useCallApi();
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageData: {
                title,
                option1,
                option2,
                requirement: { newTitle }
            }
        }
    } = lang;
    const [showFont, setShowFont] = useState(true);
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<FontFormCreateInterface>();

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiFontId(entityId), HTTP_METHODS.GET);
        const { data } = response;
        setEntity(data);
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(FontFormValues);
        }
    }, [id]);

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/dataIconB.svg'
                kind='settings'
                option='Data'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/data-font' }}
                    icon='DataIconB.svg'
                    subtitle={{
                        name: id ? entity?.name ?? '' : '',
                        link: `/panel/data-font/edit/${id}`
                    }}
                    subsubtitle={{ name: 'Requerimientos' }}
                />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='ReqPage/RequirementList'
                    >
                        <div className='settings__grid-container__item__title title'>
                            <Link
                                className={`settings__grid-container__item__title__section2__item left ${
                                    !showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(false)}
                                to='/panel/data'
                            >
                                {option1}
                            </Link>
                            <Link
                                className={`settings__grid-container__item__title__section2__item right ${
                                    showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(true)}
                                to='/panel/data-font'
                            >
                                {option2}
                            </Link>
                        </div>
                        <RequirementsTableComponent />
                        <div
                            className='settings__grid-container__item__content'
                            id='ReqPage/RequirementList/CreateButton'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/data-font/${id}/requirements/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Requirements;
