export interface ProductServiceInterface {
    id: string;
    name: string;
    description: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface ProductServiceFormCreateInterface {
    name: string;
    description: string;
}

export const ProductServiceFormValues: ProductServiceFormCreateInterface = {
    name: '',
    description: ''
};
