import { AppFunctionListInterface } from './app-function.interface';
import { AppSectionInterface } from './app-section.interface';

export interface AppComponentListInterface {
    id: string;
    name: string;
    tag: string;
    code: string;
    functionType: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    appFunctions?: AppFunctionListInterface[];
}

export interface AppComponentInterface {
    id: string;
    name: string;
    tag: string;
    code: string;
    functionType: string;
    state: number;
    type?: string;
    createdAt: string;
    updatedAt: string;
    appSection?: AppSectionInterface;
}

export interface AppComponentFormCreateInterface {
    name: string;
    tag: string;
    code: string;
    functionType: string;
    appSection?: string;
}

export const AppComponentFormValues: AppComponentFormCreateInterface = {
    name: '',
    tag: '',
    code: '',
    functionType: ''
};
