export interface InvitationsInterface {
    id: string;
    email: string;
    role: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

interface RelatedIdInterface {
    client: string[];
    property: string[];
    unit: string[];
    gestor: string[];
}

export interface InvitationsFormCreateInterface {
    email: string;
    role: string;
    relatedId: RelatedIdInterface;
}

export const InvitationsFormValues: InvitationsFormCreateInterface = {
    email: '',
    role: '',
    relatedId: {
        client: [],
        property: [],
        unit: [],
        gestor: []
    }
};
