//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { PermissionStoreInterface } from '../../../store/slices/permissions/permissions.interface';
import { useAppSelector } from '../../../store/hooks';
import { lang } from '../../langs';
import TreeComponent from './TreeComponent';

const ProductServicesPage = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const {
        permissionsData: { productAndService }
    }: PermissionStoreInterface = useAppSelector(state => state.permissions);
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageProductServiceSettings: { title }
            // detailsTitle
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Productos y Servicios'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: `${title} ${productAndService.name}` }}
                    icon='GestorsIconB.svg'
                />
                <div className='settings__grid-container'>
                    <TreeComponent />
                </div>
            </div>
        </>
    );
};

export default ProductServicesPage;
