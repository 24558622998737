import './styles.scss';
import { SetStateAction, MouseEvent, Dispatch } from 'react';
import { IEventInfo } from '../calendar';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
    open: boolean;
    handleClose: Dispatch<SetStateAction<void>>;
    onDeleteEvent: (e: MouseEvent<HTMLButtonElement>) => void;
    currentEvent: IEventInfo | null;
    color?: string; // Nueva propiedad opcional para el color del borde
}

const EventInfoModal = ({
    open,
    handleClose,
    onDeleteEvent,
    currentEvent,
    color
}: IProps) => {
    const onClose = () => {
        handleClose();
    };

    const formattedDate = currentEvent?.start
        ? currentEvent.start.toLocaleDateString('es-ES', {
              weekday: 'long', // "jueves"
              year: 'numeric', // "2024"
              month: 'long', // "noviembre"
              day: 'numeric' // "7"
          })
        : 'Fecha no disponible';

    const getRecurrenceMessage = recurrentDetail => {
        const days = recurrentDetail.days; // [1, 3, 5]
        const weeks = recurrentDetail.weeks; // 1 o 2

        // Mapeo de los números de días a sus nombres
        const diasDeLaSemana = {
            1: 'lunes',
            2: 'martes',
            3: 'miércoles',
            4: 'jueves',
            5: 'viernes',
            6: 'sábado',
            7: 'domingo'
        };

        // Filtrar los días seleccionados por su nombre
        const diasSeleccionados = days.map(day => diasDeLaSemana[day]);

        // Crear la base del mensaje con los días
        let mensaje = `Todos los ${diasSeleccionados.join(', ')}`;

        // Añadir la información de las semanas si es necesario
        if (weeks === 2) {
            mensaje += `, cada ${weeks} semanas`;
        }

        return mensaje;
    };

    // Si `open` es false, retornamos `null` para evitar renderizar nada
    if (!open) return null;

    return (
        <div className='eventInfoModal'>
            <div className={`eventInfoModal__main`}>
                <div className='eventInfoModal__main__menu subsubtitle'>
                    <CloseOutlined
                        className='eventInfoModal__main__menu__close'
                        onClick={onClose}
                        rev={''}
                    />
                    <hr />
                </div>
                <div className='eventInfoModal__main__header title'>
                    <div className='eventInfoModal__main__header__title'>
                        <div
                            className='eventInfoModal__main__header__title__circle'
                            style={{
                                backgroundColor: `${color ?? '#b64fc8'}` // Color del borde izquierdo
                            }}
                        />
                        <div className='eventInfoModal__main__header__title__text'>
                            {currentEvent?.title}
                        </div>
                    </div>
                    <div className='eventInfoModal__main__header__date subtitle'>
                        <div />
                        {formattedDate} - {currentEvent?.hour}
                    </div>
                    {currentEvent?.recurrentDetail && (
                        <div className='eventInfoModal__main__header__date subtitle'>
                            <div />
                            {getRecurrenceMessage(
                                currentEvent?.recurrentDetail
                            )}
                        </div>
                    )}
                </div>
                <div className='eventInfoModal__main__content subtitle'>
                    <div className='eventInfoModal__main__content__item'>
                        <div className='eventInfoModal__main__content__item__title'>
                            Gestor:
                        </div>
                        <div className='eventInfoModal__main__content__item__detail'>
                            <div className='eventInfoModal__main__content__item__detail__imgContainer'>
                                <img
                                    className='eventInfoModal__main__content__item__detail__imgContainer__img'
                                    src='/svg-icons/eventicon.svg'
                                />
                            </div>
                            <div className='eventInfoModal__main__content__item__detail__text'>
                                <div className='eventInfoModal__main__content__item__detail__text__name'>
                                    {currentEvent?.gestorName}
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text__info'>
                                    Contacto: {currentEvent?.gestorContactName}{' '}
                                    - {currentEvent?.gestorContactEmail}
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text__info'>
                                    {currentEvent?.description}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='eventInfoModal__main__content__item'>
                        <div className='eventInfoModal__main__content__item__title'>
                            Inmueble:
                        </div>
                        <div className='eventInfoModal__main__content__item__detail'>
                            <div className='eventInfoModal__main__content__item__detail__imgContainer'>
                                <img
                                    className='eventInfoModal__main__content__item__detail__imgContainer__img'
                                    src='/svg-icons/eventicon.svg'
                                />
                            </div>
                            <div className='eventInfoModal__main__content__item__detail__text'>
                                <div className='eventInfoModal__main__content__item__detail__text__name'>
                                    {currentEvent?.propertyName}
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text__info'>
                                    Dirección: {currentEvent?.propertyAddress}
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text__info'>
                                    Estado de almacenamiento: -
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='eventInfoModal__main__content subtitle'>
                    <div className='eventInfoModal__main__content__item'>
                        <div className='eventInfoModal__main__content__item__title'>
                            Nota:
                        </div>
                    </div>
                    {currentEvent?.note && (
                        <div className='eventInfoModal__main__content__item__detail'>
                            <div className='eventInfoModal__main__content__item__detail__text'>
                                <div className='eventInfoModal__main__content__item__detail__text__name'>
                                    {currentEvent?.gestorUserName}
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text__note'>
                                    {currentEvent?.note}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventInfoModal;
