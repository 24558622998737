import './create-calendar.scss';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
import CreateEvent from './kind/createEvent';
import CalendarRequest from './kind/calendarRequest';

interface Props {
    onClose: () => void;
}

const CreateCalendar = ({ onClose }: Props) => {
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile].name;
    return (
        <div className='calendarRequest'>
            <div className={`calendarRequest__main`}>
                {selectedRole.includes('BZero') ? (
                    <CreateEvent onClose={onClose} />
                ) : (
                    <CalendarRequest onClose={onClose} />
                )}
            </div>
        </div>
    );
};

export default CreateCalendar;
