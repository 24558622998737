import '../settings.scss';
//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import InvitesTableComponent from './components/invites-table';
import ButtonBody from '../../../components/button/button';
//  <--INTERFACE--> //
import { lang } from '../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';

const User = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;

    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageInvites: { title, subtitle, adminTitle, createTitle }
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='setting'
                title={title}
                icon='settings/usersIcon.svg'
                kind='settings'
                option='User'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/user' }}
                    icon='UsersIconB.svg'
                    subtitle={{ name: subtitle }}
                />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='InvitPage/InvitationsList'
                    >
                        <div className='settings__grid-container__item__title subtitle'>
                            {adminTitle}
                        </div>
                        <InvitesTableComponent />
                        <div
                            className='settings__grid-container__item__content'
                            id='InvitPage/InvitationsList/New'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={createTitle}
                                path='/panel/invitations/create'
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
        /* <Container>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <PageTitle title={title} icon='settings/propertiesB.svg' />
            <Wrapper>
                <WrapperButtons className='app-d-flex__right'>
                    <Link to={'./create'} className='btn bzero_btn_primary'>
                        {buttonNew}
                    </Link>
                </WrapperButtons>
                <TableComponent
                    data={dataList}
                    headers={InvitationsTable}
                    showFilters={true}
                    keysFilter={keysFilter}
                    showPagination={true}
                    actionsButtons={{ onDelete: Delete }}
                />
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container> */
    );
};

export default User;
