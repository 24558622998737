import '../../../../settings/settings.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import { ClienBusinessUsersTable } from '../../../../../components/table/data/columns_table';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    ClientRoleInterface,
    ClientUserInterface
} from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiClientUserByClient,
    ApiClientRoleByClient,
    ApiClientUserByClientBusiness,
    ApiClientUserMany
} from '../../../../../config/service';
//  <--OTHERS--> //
import '../../../../../scss/global/global.scss';
import './index.scss';
import ProductServicesPage from '..';
import _ from 'lodash';

interface RoleInterface {
    roleId: string;
}

interface UserRolesAssign {
    clientUser: string;
    clientEmail: string;
    addUsers?: boolean;
    roles: RoleInterface[];
}

const CreateEditPage = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef
        // ConfigAlert,
        // setConfigAlert,
        // Defaults
    } = useAlert({ defaultLang });
    const { clientBusinessId, id: clientId } = useParams();

    const [dataList, setDataList] = useState<ClientUserInterface[]>([]);
    const [usersByClient, setUsersByClient] = useState<ClientUserInterface[]>(
        []
    );
    const [clientRole, setClientRole] = useState<ClientRoleInterface[]>([]);
    const [finalUserRoles, setfinalUserRoles] = useState<any>([]);
    const [finalNewUserRoles, setfinalNewUserRoles] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [togleUsers, setTogleUsers] = useState<boolean>(false);

    // const [preSelected, setPreSelected] = useState<any>([]);

    useEffect(() => {
        if (dataList.length > 0) {
            const selectedList: UserRolesAssign[] = [];
            for (let i = 0; i < dataList.length; i++) {
                const userItem: any = dataList[i];
                const roleArray: any = [];
                for (let e = 0; e < userItem.rolesCount; e++) {
                    const role = userItem[`clientRole${e + 1}`];
                    const roleExplode = role.split(' / ');
                    const crData = roleExplode[0];
                    const roleItem = {
                        roleId: crData.substring(3, crData.length)
                    };
                    roleArray.push(roleItem);
                }
                const objectUserRole = {
                    clientEmail: userItem.email,
                    clientUser: userItem.id,
                    roles: roleArray
                };
                selectedList.push(objectUserRole);
            }
            setfinalUserRoles(selectedList);
            setIsEdit(true);
        }
    }, [dataList]);

    const GetRoles = async () => {
        if (clientId) {
            const response = await GetData(
                ApiClientRoleByClient(clientId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setClientRole(data);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const GetAllUsers = async (previousData: ClientRoleInterface[]) => {
        if (clientId) {
            const response = await GetData(
                ApiClientUserByClient(clientId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            const results: any = _.xorBy(data, previousData, 'email'); // or 'nome'
            setUsersByClient(results);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    useEffect(() => {
        GetRoles();
    }, [clientId]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        if (clientBusinessId) {
            const response = await GetData(
                ApiClientUserByClientBusiness(clientBusinessId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setDataList(data);
            GetAllUsers(data);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const SavePermissions = async (isNew: boolean = false) => {
        const object = {
            client: clientId,
            clientBusiness: clientBusinessId,
            isEdit,
            isNew,
            permissions: !isNew ? finalUserRoles : finalNewUserRoles
        };
        if (isNew) {
            object.isEdit = false;
        }
        const response = await GetData(
            ApiClientUserMany,
            HTTP_METHODS.POST,
            object
        );
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        CallData();
    }, [clientBusinessId]);

    const HandleCheckboxChange = (
        event: any,
        user: ClientUserInterface,
        role: ClientRoleInterface,
        addUsers: boolean = false
    ) => {
        const checkSelected = event.target.checked;
        AsignData(checkSelected, user, role, addUsers);
    };

    const AsignData = (
        checkSelected,
        user: ClientUserInterface,
        role: ClientRoleInterface,
        addUsers: boolean
    ) => {
        const selectedData: UserRolesAssign = {
            clientUser: user.id,
            clientEmail: user.email,
            addUsers,
            roles: [{ roleId: role.id }]
        };

        const finalArray = !addUsers
            ? [...finalUserRoles]
            : [...finalNewUserRoles];
        if (checkSelected) {
            const existUser = finalArray.findIndex(
                (item: UserRolesAssign) =>
                    item.clientUser === selectedData.clientUser
            );
            if (existUser >= 0) {
                const actualRole = selectedData.roles[0];
                finalArray[existUser].roles.push(actualRole);
            } else {
                finalArray.push(selectedData);
            }
        } else {
            const existUser = finalArray.findIndex(
                (item: UserRolesAssign) =>
                    item.clientUser === selectedData.clientUser
            );
            if (existUser >= 0) {
                const actualRole = selectedData.roles[0];
                const indexRole = finalArray[existUser].roles.findIndex(
                    role => role.roleId === actualRole.roleId
                );
                finalArray[existUser].roles.splice(indexRole, 1);
            }
        }
        if (addUsers) {
            setfinalNewUserRoles(finalArray);
        } else {
            setfinalUserRoles(finalArray);
        }
    };

    return (
        <ProductServicesPage>
            <>
                <AlertElement ref={alertManagerRef} />
                <ToastElement ref={toastManagerRef} />
                <div>
                    {dataList.length > 0 &&
                        `Usuarios de ${dataList[0].client.name} / ${
                            dataList[0].clientBusiness.property.name
                        } ${
                            dataList[0].clientBusiness.propertyUnit
                                ? ` / ${dataList[0].clientBusiness.propertyUnit?.name} `
                                : ''
                        }`}

                    <table className='tablecontainer__tblcomponent table-hover'>
                        <thead className='tablecontainer__tblcomponent__thead'>
                            <tr className='tbl__editable__thead__tr'>
                                {ClienBusinessUsersTable.map((item, index) => (
                                    <th
                                        key={`colhead-${index}-${item.text}`}
                                        className={`tbl__editable__thead__tr__th ${item.text}-default subtitle`}
                                    >
                                        <span className='tbl__editable__thead__tr__th__text'>
                                            {item.text}
                                        </span>
                                    </th>
                                ))}
                                {clientRole.map(role => {
                                    return (
                                        <th
                                            key={`colhead-${role.id}`}
                                            className={`tbl__editable__thead__tr__th`}
                                        >
                                            <span className='tbl__editable__thead__tr__th__text'>
                                                {role.name}
                                            </span>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map(item => {
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            {`${item.user.name} ${item.user.lastName}`}
                                        </td>
                                        <td>{`${item.email}`}</td>
                                        {clientRole.map(role => {
                                            return (
                                                <td
                                                    key={`detail-${item.id}-${role.id}`}
                                                >
                                                    <input
                                                        type='checkbox'
                                                        id={`check-${item.user.id}-${role.id}`}
                                                        name='userRole'
                                                        value={role.id}
                                                        checked={finalUserRoles.find(
                                                            elm =>
                                                                elm.clientUser ===
                                                                    item.id &&
                                                                elm.roles.find(
                                                                    elm2 =>
                                                                        elm2.roleId ===
                                                                        role.id
                                                                )
                                                        )}
                                                        onChange={e => {
                                                            HandleCheckboxChange(
                                                                e,
                                                                item,
                                                                role
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <br />
                    <div className='settings__grid-container__item_right__content__btn'>
                        <button
                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                            onClick={() => {
                                setTogleUsers(!togleUsers);
                            }}
                        >
                            {togleUsers
                                ? 'Ocultar usuarios disponibles'
                                : 'Añadir usuario'}
                        </button>
                        <button
                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                            onClick={() => {
                                SavePermissions(false);
                            }}
                        >
                            Grabar
                        </button>
                    </div>
                    <div
                        className={`${
                            togleUsers ? 'showusers' : ''
                        } ctuseradddsdynamic`}
                    >
                        <table className='tablecontainer__tblcomponent table-hover'>
                            <thead className='tablecontainer__tblcomponent__thead'>
                                <tr>
                                    {ClienBusinessUsersTable.map(
                                        (item, index) => (
                                            <th
                                                key={`colhead-${index}-${item.text}`}
                                                className={`tbl__editable__thead__tr__th ${item.text}-default subtitle`}
                                            >
                                                <span className='tbl__editable__thead__tr__th__text'>
                                                    {item.text}
                                                </span>
                                            </th>
                                        )
                                    )}
                                    {clientRole.map(role => {
                                        return (
                                            <th
                                                key={`colhead-${role.id}`}
                                                className={`tbl__editable__thead__tr__th`}
                                            >
                                                <span className='tbl__editable__thead__tr__th__text'>
                                                    {role.name}
                                                </span>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {usersByClient.map((item, idx) => {
                                    return (
                                        <tr
                                            key={`elementnewuser-${item.id}-${idx}`}
                                        >
                                            <td>
                                                {`${item.user.name} ${item.user.lastName}`}
                                            </td>
                                            <td>{`${item.email}`}</td>
                                            {clientRole.map(role => {
                                                return (
                                                    <td
                                                        key={`detailtoadd-${item.id}-${role.id}`}
                                                    >
                                                        <input
                                                            type='checkbox'
                                                            id={`check-toadd-${item.user.id}-${role.id}`}
                                                            name='userRole'
                                                            value={role.id}
                                                            onChange={e => {
                                                                HandleCheckboxChange(
                                                                    e,
                                                                    item,
                                                                    role,
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <button
                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                            onClick={() => {
                                SavePermissions(true);
                            }}
                        >
                            Agregar nuevos usuarios
                        </button>
                    </div>
                    {LoadingData && <LoaderElement />}
                </div>
            </>
        </ProductServicesPage>
    );
};

export default CreateEditPage;
