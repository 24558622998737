import { useParams } from 'react-router';
//  <--COMPONENTS--> //
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import { ClientRoleInterface } from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiClientRoleByClient,
    ApiClientUserId
} from '../../../../../config/service';
//  <--OTHERS--> //
import ProductServicesPage from '..';
import { useEffect, useState } from 'react';

const CreateEditPage = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { id: clientId, clientUserId } = useParams();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    const [data, setData] = useState<ClientRoleInterface[]>([]);
    const [dataOfPsc, setDataOfPsc] = useState<any>([]);
    const [selectedRole, setSelectedRole] = useState<string>('');

    useEffect(() => {
        if (data && dataOfPsc) {
            setSelectedOptions(dataOfPsc.clientRole);
        }
    }, [dataOfPsc, data]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        if (clientId) {
            const response = await GetData(
                ApiClientRoleByClient(clientId),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            const sortedData = [...data].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            setData(sortedData);
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const CallDataOfProductServiceClient = async () => {
        if (clientUserId) {
            const response = await GetData(
                ApiClientUserId(clientUserId),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
            setDataOfPsc(data);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the COMPANY list
     */
    useEffect(() => {
        CallData();
        CallDataOfProductServiceClient();
    }, []);

    const SaveConfig = async () => {
        if (clientUserId) {
            const payload = {
                clientRole: selectedRole
            };
            const response = await GetData(
                ApiClientUserId(clientUserId),
                HTTP_METHODS.PATCH,
                payload
            );
            const { message, data, status } = response;
            if (status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                setConfigToast(ConfigToast);
            } else {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const ChangeRole = (e: any) => {
        setSelectedRole(e.target.value);
    };

    return (
        <ProductServicesPage>
            <>
                <AlertElement ref={alertManagerRef} />
                <ToastElement ref={toastManagerRef} />
                <div className='settings__grid-container__item_right'>
                    <div>
                        <select
                            name='lstRoles'
                            id='lstRoles'
                            onChange={e => {
                                ChangeRole(e);
                            }}
                            defaultValue={
                                selectedOptions ? selectedOptions.id : ''
                            }
                            defaultChecked={
                                selectedOptions ? selectedOptions.id : ''
                            }
                        >
                            <option value=''>Elige una opción</option>
                            {data.map(role => {
                                return (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                );
                            })}
                        </select>
                        <button
                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                            onClick={SaveConfig}
                        >
                            Grabar
                        </button>
                        <p> {JSON.stringify(selectedOptions)} </p>
                    </div>
                    {LoadingData && <LoaderElement />}
                </div>
            </>
        </ProductServicesPage>
    );
};

export default CreateEditPage;
