import { useEffect, useState } from 'react';
import 'react-dropdown-tree-select/dist/styles.css';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
import {
    ApiAppSectionByClientId,
    ApiClientRoleDetail,
    ApiClientRoleDetailByClientRole
} from '../../../../../config/service';
import {
    AppComponentListInterface,
    AppSectionInterface
} from '../../../../../interface';
import './style.scss';
import { useParams } from 'react-router';

interface ElementType {
    type: 'section' | 'component' | 'function';
}

const TreeComponent = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { id, roleEdit: clientRoleId } = useParams();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [sectionOpened, setSectionOpened] = useState<string[]>([]);
    const [componentOpened, setComponentOpened] = useState<string[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    const [data, setData] = useState<AppSectionInterface[]>([]);
    const [dataOfPs, setDataOfPs] = useState<any>([]);
    const [foundElements, setFoundElements] = useState<any>([]);

    useEffect(() => {
        if (data && dataOfPs) {
            if (dataOfPs.length > 0) {
                const found: any = [];
                for (let i: number = 0; i < dataOfPs.length; i++) {
                    const item = dataOfPs[i];
                    const { appSection, appComponent, appFunction } = item;
                    if (appSection) {
                        const elementSection = data.find(
                            item => item.id === appSection.id
                        );
                        const objectSection = {
                            ...elementSection,
                            type: 'section'
                        };
                        found.push(objectSection);
                    }

                    if (appComponent) {
                        data.map(section => {
                            const elementComponent =
                                section.appComponents?.find(
                                    item => item.id === appComponent.id
                                );
                            if (elementComponent) {
                                const objectComponent = {
                                    ...elementComponent,
                                    type: 'component'
                                };
                                found.push(objectComponent);
                            }
                            return null;
                        });
                    }
                    if (appFunction) {
                        data.map(section => {
                            const { appComponents } = section;
                            appComponents?.map(component => {
                                const elementFunction =
                                    component.appFunctions?.find(
                                        item => item.id === appFunction.id
                                    );
                                if (elementFunction) {
                                    const objectFunction = {
                                        ...elementFunction,
                                        type: 'function'
                                    };
                                    found.push(objectFunction);
                                }
                                return null;
                            });
                            return null;
                        });
                    }
                }
                if (found[0].id) {
                    setFoundElements(found);
                }
            }
        }
    }, [dataOfPs, data]);

    useEffect(() => {
        if (foundElements.length > 0) {
            for (let i: number = 0; i < foundElements.length; i++) {
                const found = foundElements[i];
                if (found.type === 'section') {
                    delete found.roles;
                    delete found.appComponents;
                    delete found.appFunctions;
                }
            }
            setSelectedOptions(foundElements);
        }
    }, [foundElements]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        if (id) {
            const response = await GetData(
                ApiAppSectionByClientId(id),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            const sortedData = [...data].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            setData(sortedData);
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const CallDataOfProductService = async () => {
        if (clientRoleId) {
            const response = await GetData(
                ApiClientRoleDetailByClientRole(clientRoleId),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
            setDataOfPs(data);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the COMPANY list
     */
    useEffect(() => {
        CallData();
        CallDataOfProductService();
    }, []);

    const Toggle = (
        data: AppSectionInterface | AppComponentListInterface,
        element: ElementType
    ) => {
        const clicked =
            element.type === 'section'
                ? [...sectionOpened]
                : [...componentOpened];
        const selected = clicked.findIndex(item => item === data.id);
        if (selected >= 0) {
            clicked.splice(selected, 1);
        } else {
            clicked.push(data.id);
        }
        if (element.type === 'section') {
            setSectionOpened(clicked);
        } else {
            setComponentOpened(clicked);
        }
    };

    const HandleCheckboxChange = (
        event: any,
        data: any,
        element: ElementType
    ) => {
        const checkSelected = event.target.checked;
        AsignData(data, element, checkSelected);
    };

    const AsignData = (data, element, checkSelected) => {
        const finalArray = [...selectedOptions];
        const newData = { ...data, type: element.type };
        delete newData.roles;
        delete newData.appComponents;
        delete newData.appFunctions;
        if (checkSelected) {
            finalArray.push(newData);
        } else {
            const index = finalArray.findIndex(item => item.id === newData.id);
            finalArray.splice(index, 1);
        }
        setSelectedOptions(finalArray);
    };

    const SaveConfig = async () => {
        const arrayTosend = selectedOptions.map(item => {
            const object = {
                clientRole: clientRoleId,
                appSection: item.type === 'section' ? item.id : null,
                appComponent: item.type === 'component' ? item.id : null,
                appFunction: item.type === 'function' ? item.id : null
            };
            return object;
        });
        const payload = {
            data: arrayTosend,
            clientRole: clientRoleId
        };
        const response = await GetData(
            ApiClientRoleDetail,
            HTTP_METHODS.POST,
            payload
        );
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    return (
        <div>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <ul className='settings-tree'>
                {data.map(level1 => {
                    return (
                        <li
                            className={`level1 ${
                                sectionOpened.findIndex(
                                    item => item === level1.id
                                ) >= 0
                                    ? 'open'
                                    : level1.appComponents &&
                                      level1.appComponents?.length > 0
                                    ? 'close'
                                    : ''
                            } `}
                            key={level1.id}
                        >
                            <button
                                onClick={() => {
                                    Toggle(level1, { type: 'section' });
                                }}
                            >
                                {level1.name}
                            </button>
                            <input
                                type='checkbox'
                                id={`section-${level1.id}`}
                                name='appsections'
                                value={level1.id}
                                checked={selectedOptions.find(
                                    item => item.id === level1.id
                                )}
                                onChange={e => {
                                    HandleCheckboxChange(e, level1, {
                                        type: 'section'
                                    });
                                }}
                            />
                            {level1.appComponents &&
                                level1.appComponents?.length > 0 && (
                                    <ul className='ul_lvl2'>
                                        {level1.appComponents.map(level2 => {
                                            return (
                                                <li
                                                    className={`level2 ${
                                                        componentOpened.findIndex(
                                                            item =>
                                                                item ===
                                                                level2.id
                                                        ) >= 0
                                                            ? 'open'
                                                            : level2.appFunctions &&
                                                              level2
                                                                  .appFunctions
                                                                  ?.length > 0
                                                            ? 'close'
                                                            : ''
                                                    } `}
                                                    key={level2.id}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            Toggle(level2, {
                                                                type: 'component'
                                                            });
                                                        }}
                                                    >
                                                        {level2.name}
                                                    </button>
                                                    <input
                                                        type='checkbox'
                                                        id={`component-${level2.id}`}
                                                        name='appcomponent'
                                                        value={level2.id}
                                                        checked={selectedOptions.find(
                                                            item =>
                                                                item.id ===
                                                                level2.id
                                                        )}
                                                        onChange={e => {
                                                            HandleCheckboxChange(
                                                                e,
                                                                level2,
                                                                {
                                                                    type: 'component'
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    {level2.appFunctions &&
                                                        level2.appFunctions
                                                            ?.length > 0 && (
                                                            <ul className='ul_lvl3'>
                                                                {level2.appFunctions.map(
                                                                    level3 => {
                                                                        return (
                                                                            <li
                                                                                className='level3 close'
                                                                                key={
                                                                                    level3.id
                                                                                }
                                                                            >
                                                                                <button>
                                                                                    {
                                                                                        level3.name
                                                                                    }
                                                                                </button>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={`function-${level3.id}`}
                                                                                    name='appfunction'
                                                                                    value={
                                                                                        level3.id
                                                                                    }
                                                                                    checked={selectedOptions.find(
                                                                                        item =>
                                                                                            item.id ===
                                                                                            level3.id
                                                                                    )}
                                                                                    onChange={e => {
                                                                                        HandleCheckboxChange(
                                                                                            e,
                                                                                            level3,
                                                                                            {
                                                                                                type: 'function'
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                        </li>
                    );
                })}
            </ul>
            <button
                className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                onClick={SaveConfig}
            >
                Grabar
            </button>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default TreeComponent;
